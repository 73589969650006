import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { EndlessHolidayInvitationsTableRow } from '@modules/product-package/invites/table-row'
import { EndlessHolidayInvitation } from '@modules/product-package/models'
import { ProductPackageSet } from '@models/products'

const headerValues: TableHeaderValue[] = [
  { title: 'Lp.' },
  { title: 'Email' },
  { title: 'Status' },
  { title: 'Zestaw' },
  { title: 'Sprzedawca' },
  { title: 'Data ważności rabatu' },
  { title: 'Data utworzenia' },
  { title: 'Data-auto anulowania' },
  { title: '' },
]

interface Props {
  invitations: EndlessHolidayInvitation[]
  setFilters: (filters: BaseTableFilters) => void
  filters: TableFilters
  isLoading: boolean
  productPackageSets: ProductPackageSet[]
}

export const EndlessHolidayInvitationsTable = ({
  setFilters,
  invitations,
  filters,
  isLoading,
  productPackageSets,
}: Props): React.ReactNode => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {invitations.map((invitation: EndlessHolidayInvitation, index: number) => (
      <EndlessHolidayInvitationsTableRow
        key={invitation.id}
        invitation={invitation}
        lp={index + 1}
        productPackageSets={productPackageSets}
      />
    ))}
  </Table>
)

import * as React from 'react'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { IconWithText } from '@components/icon-with-text'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ReceptionBooking } from '@models/reception'
import { ReservationGuestContactData } from '@modules/reservations/details/base-data/guest/reservation-guest-contact-data'

interface Props {
  withTitle?: boolean
  withProfilePreview?: boolean
  booking: ReceptionBooking
  clientId: number
}

export const ReservationGuestData = ({ withTitle, withProfilePreview, booking, clientId }: Props): JSX.Element => {
  const hasActiveSubscription = true

  const navigate = useNavigate()

  const showGuestProfile = () => navigate(NavigationPath.CrmClientsWitParams.replace(':client_id', String(clientId)))

  return (
    <div className="mt-2">
      {withTitle && <span>Imię i nazwisko:</span>}
      <div className="d-flex align-items-center">
        <strong className="d-flex align-items-center font-20">{booking.name}</strong>
        {hasActiveSubscription && (
          <BadgeWithIcon
            icon="mdi-check mdi"
            title="subskrybent"
            variant="success"
            className="px-1 h-fit-content ml-3"
          />
        )}
      </div>

      {withProfilePreview && (
        <IconWithText icon="uil-eye lh-0" text="Pokaż profil Gościa" textClass="font-11" onClick={showGuestProfile} />
      )}
      <ReservationGuestContactData emailClassName="mt-3" booking={booking} />
    </div>
  )
}

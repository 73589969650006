import * as React from 'react'
import { Row } from 'reactstrap'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedPromotions } from '@components/hooks/use-grouped-promotions'
import { SubscriptionCreateFormInformationRecommender } from '@modules/subscription/create/form-information-recommender'
import { FormSelect } from '@hyper/forms/form-select'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  subscriptionDetails?: SubscriptionDetails
}

export const SubscriptionCreateFormInformation: React.FC<Props> = ({ subscriptionDetails }) => {
  const user = useAuthenticatedUser()
  const sources = useGroupedSources()
  const sellers = useGroupedSellers([])
  const promotions = useGroupedPromotions()

  const canChangeSeller = () => {
    if (subscriptionDetails?.type === 'BOOKING_IMPROVEMENT') {
      return user.hasPerm(UserPermission.BookingCanChangeSellerInSubscriptionBookingImprovement)
    }
    return true
  }

  return (
    <Row>
      <FormSelect
        options={sellers}
        name="seller"
        label="Sprzedawca"
        formPlainProps={{ colSize: 6 }}
        selectProps={{ isDisabled: !canChangeSeller() }}
      />
      <FormSelect
        options={promotions}
        name="promotion_marketing"
        label="Promocja marketingowa"
        formPlainProps={{ colSize: 6 }}
      />
      <FormSelect
        options={sources}
        name="source_marketing"
        label="Źródło marketingowe"
        formPlainProps={{ colSize: 6 }}
      />

      <SubscriptionCreateFormInformationRecommender colSize={6} />
    </Row>
  )
}

import * as React from 'react'
import { getReactSelectStyles } from '@components/custom-react-select'
import { FormSelect, FormSelectProps } from '@hyper/forms/form-select'
import { FormInput, FormInputProps } from '@hyper/forms/index'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { Label } from 'reactstrap'
import classNames from 'classnames'

interface Props {
  selectProps: FormSelectProps
  inputProps: FormInputProps
  label?: string
  wrapperClassName?: string
  clearInputOnChange?: boolean
}

export const FormInputWithSelect = ({
  label,
  selectProps,
  inputProps,
  wrapperClassName,
  clearInputOnChange = true,
}: Props): React.ReactNode => {
  const { control, setValue } = useFormContext()
  const selectValue = useWatch({ control, name: selectProps.name })

  useDidUpdateEffect(() => {
    if (clearInputOnChange) {
      setValue(inputProps.name, null)
    }
  }, [selectValue?.value])

  return (
    <div className={wrapperClassName}>
      {label && <Label>{label}</Label>}
      <div className="d-flex">
        <FormSelect
          options={selectProps.options}
          name={selectProps.name}
          selectProps={{ selectStyles, ...selectProps.selectProps }}
          formPlainProps={{ colSize: 6, colClassName: 'px-0', ...selectProps.formPlainProps }}
        />
        <FormInput
          colSize={6}
          {...inputProps}
          inputProps={{ style: { ...inputProps.inputProps, ...inputStyles } }}
          colClassName={classNames('px-0', inputProps.colClassName)}
        />
      </div>
    </div>
  )
}

const inputStyles = {
  height: 35,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderLeft: 'none',
  borderColor: '#DEE2E6',
}

const selectStyles = {
  ...getReactSelectStyles({ hasError: false }),
  control: provided => ({
    ...provided,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 35,
    minHeight: 35,
    boxShadow: 'none',
    border: '1px solid #DEE2E6',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#DEE2E6',
    },
  }),
}

import * as React from 'react'
import { SubscriptionCode } from '@modules/subscription/models'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { SubscriptionDetailsCodesRowRenewAction } from '@modules/subscription/details/code-rows-renew-action'

interface Props {
  subscriptionCode: SubscriptionCode
  canUpdate: boolean
  canRenew: boolean
}

export const SubscriptionDetailsCodesRowActions: React.FC<Props> = ({ subscriptionCode, canUpdate, canRenew }) => {
  const subscriptionDetails = useAppSelector((state: RootState) => state.subscriptionState.subscriptionDetails)

  const [handleConvert] = useModal('SubscriptionCodeConvertModal', { subscriptionCode, subscriptionDetails })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRollback } = useApiRequest(async () => {
    dispatch(updateSubscriptionDetails(await commonObjectPatch(subscriptionCode.urls.convert)))
    addSuccessMessage('Sukces', `Kod ${subscriptionCode.code} został przywrócony!`)
  })

  return (
    <Table.Cell style={{ width: 10 }} className="text-nowrap">
      <SubscriptionDetailsCodesRowRenewAction subscriptionCode={subscriptionCode} canRenew={canRenew} />
      {subscriptionCode.kind == 'inactive' && canUpdate && (
        <SaveButton
          type="button"
          isSaving={isLoading}
          onClick={handleRollback}
          className="btn btn-xs btn-danger mr-1"
          label="Przywróć"
          labelSaving="Przywracanie"
        />
      )}

      {(!subscriptionCode.is_used || (subscriptionCode.is_used && subscriptionCode.splitted_used > 0)) &&
        (subscriptionCode.kind === 'default' || subscriptionCode.kind === 'localization') &&
        canUpdate && (
          <button onClick={handleConvert} type="button" className="btn btn-xs btn-green mr-1">
            Konwertuj
          </button>
        )}
    </Table.Cell>
  )
}

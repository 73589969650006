import * as React from 'react'
import { Package } from '@models/package'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { getDashboardAppUrl } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPackageDetails } from '@store/actions/package-actions'
import { PackageDetailsTabs } from '@modules/package/details/tabs'
import { PackageDetailsBaseInformation } from '@modules/package/details/information/base'
import { PackageDetailsClientInformation } from '@modules/package/details/information/client'
import { PackageDetailsPaymentsList } from '@modules/package/details/payments/list'
import { PackageDetailsCodesList } from '@modules/package/details/codes'
import { PackageDetailsNotes } from '@modules/package/details/notes'
import { DocumentList } from '@components/documents/document-list'
import { PackageDetailsCodesDownload } from '@modules/package/details/codes/download'
import { PackageDetailsBaseInformationCancel } from '@modules/package/details/information/base/cancel'
import { BaseModalProps } from '@components/modals/types'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { PackageDetailsEntranceCodes } from '@modules/package/details/entrance-codes'
import { HistoryBox } from '@modules/promotions/common/history-box'

interface PackageDetailsProps extends BaseModalProps {
  packageData: Package
}

export const PackageDetailsModal: React.FC<PackageDetailsProps> = ({ packageData, toggleIsVisible }) => {
  const packageDetails = useAppSelector((state: RootState) => state.packageState.packageDetails)
  const dispatch = useAppDispatch()
  const { notification_emails } = usePackageAppData()

  const informationRef = React.useRef<HTMLDivElement>(null)
  const paymentsRef = React.useRef<HTMLDivElement>(null)
  const codesRef = React.useRef<HTMLDivElement>(null)
  const notesRef = React.useRef<HTMLDivElement>(null)

  const refreshPackage = async () => dispatch(getPackageDetails(packageData.urls.details))
  const { isLoading, action: fetch } = useApiRequest(async () => await refreshPackage())

  React.useEffect(() => {
    fetch()
  }, [])

  const type = { HPI: 'HPI', HPR: 'HPR' }[packageData.package_type]

  const copyValue = React.useMemo(
    () =>
      packageData
        ? getDashboardAppUrl(
            NavigationPath.PackageListWithParams.replace(':type', packageData.package_type.toLocaleLowerCase()).replace(
              ':id',
              String(packageData.id),
            ),
          )
        : '',
    [packageData, type],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Pakiet firmowy <span className="text-semi-strong">{packageData.number}</span>
        <CopyToClipboard className="uil-share-alt ml-1" value={copyValue} />
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {packageDetails && (
            <>
              <div className="modal-details__tabs-wrapper">
                <PackageDetailsTabs
                  informationRef={informationRef}
                  paymentsRef={paymentsRef}
                  codesRef={codesRef}
                  notesRef={notesRef}
                />
              </div>
              <div ref={informationRef} />
              <Row>
                <Col md={6}>
                  <PackageDetailsBaseInformation packageDetails={packageDetails} />
                  <DocumentList
                    documents={packageDetails.documents}
                    handleOnSave={refreshPackage}
                    url={packageDetails.urls.documents}
                  />
                </Col>
                <Col md={6}>
                  <PackageDetailsClientInformation packageDetails={packageDetails} />
                  <div ref={paymentsRef} />
                  <PackageDetailsPaymentsList packageDetails={packageDetails} />
                </Col>
              </Row>
              <div ref={codesRef} />
              <Row>
                <Col md={12}>
                  <PackageDetailsCodesList localizationKind="sea" packageDetails={packageDetails} />
                  <PackageDetailsCodesList localizationKind="mountains" packageDetails={packageDetails} />
                  {packageDetails.package_type === 'HPI' && (
                    <PackageDetailsCodesList localizationKind="universal" packageDetails={packageDetails} />
                  )}
                  {packageDetails.package_type === 'HPI' && packageDetails.with_zoo_borysew_tickets && (
                    <PackageDetailsEntranceCodes
                      title="Bilety do Zoo Borysew"
                      entranceTickets={packageDetails.entrance_tickets}
                      downloadUrl={packageDetails.urls.entrance_tickets_download}
                      codesActive={packageDetails?.status === 'confirmed'}
                    />
                  )}
                </Col>
              </Row>
              <div ref={notesRef} />
              <Row>
                <Col md={6}>
                  <PackageDetailsNotes packageDetails={packageDetails} />
                  <ProductEmailNotifications
                    history={packageDetails.notifications}
                    notificationEmails={notification_emails}
                    url={packageDetails.urls.notifications}
                    email={packageDetails.email}
                    onSuccess={refreshPackage}
                  />
                </Col>
                <Col md={6}>
                  <HistoryBox history={packageDetails.history} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6}>
            <PackageDetailsBaseInformationCancel packageData={packageData} />
          </Col>
          <Col md={6} className="text-right">
            {packageDetails && <PackageDetailsCodesDownload btnClassName="mr-1" packageDetails={packageDetails} />}

            <Button color="light" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}

import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SubscriptionGenericView } from '@modules/subscription-generic/list'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { SubscriptionType } from '@modules/subscription/models'

type Params = 'kind' | 'id'

export const ProductPackageSubscriptionsListView = (): React.ReactNode => {
  const { kind } = useParams<Params>()
  const navigate = useNavigate()

  const data = {
    PRODUCT_PACKAGE: {
      menu: NavigationPath.ProductPackage.replace(':kind', 'endless_holiday'),
    },
    PRODUCT_PACKAGE_VOUCHER: {
      menu: NavigationPath.ProductPackage.replace(':kind', 'voucher'),
    },
  }[kind ?? '']

  useMenuActive(data?.menu ?? '')

  if (!data) {
    navigate(NavigationPath.Home)
    return
  }

  return <SubscriptionGenericView type={kind as SubscriptionType} />
}

import * as React from 'react'
import { Subscription, SubscriptionDetails } from '@modules/subscription/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { SubscriptionDetailsCodes } from '@modules/subscription/details/codes'
import { SubscriptionDetailsInformation } from '@modules/subscription/details/information'
import { SubscriptionDetailsPayments } from '@modules/subscription/details/payments'
import { SubscriptionDetailsBookings } from '@modules/subscription/details/bookings'
import { SubscriptionDetailsPaymentsTransfer } from '@modules/subscription/details/payments-transfer'
import { SubscriptionDetailsTabs } from '@modules/subscription/details/tabs'
import { useApiRequest } from '@components/hooks/use-api-request'
import { SubscriptionDetailsTouristVouchers } from '@modules/subscription/details/tourist-vouchers'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { NavigationPath } from '@models/routes'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { getDashboardAppUrl } from '@helpers/utils'
import { SubscriptionDetailsCancel } from '@modules/subscription/details/cancel'
import { BaseModalProps } from '@components/modals/types'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { PackageDetailsEntranceCodes } from '@modules/package/details/entrance-codes'
import { useSubscriptionOptions } from '@modules/subscription/hooks/use-subscription-options'
import { clearSubscriptionDetails, setSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useDetailsModalUnmountParamsClear } from '@components/hooks/use-details-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface SubscriptionDetailsProps extends BaseModalProps {
  subscription: Subscription
}

export const SubscriptionDetailsModal: React.FC<SubscriptionDetailsProps> = ({ subscription, toggleIsVisible }) => {
  const subscriptionDetails = useAppSelector((state: RootState) => state.subscriptionState.subscriptionDetails)
  useDetailsModalUnmountParamsClear(subscription.id)

  const { notifications, crud_permissions } = useSubscriptionTypeStrategyProvider(subscription.type)
  const user = useAuthenticatedUser()

  const dataRef = React.useRef<HTMLDivElement>(null)
  const transferRef = React.useRef<HTMLElement>(null)
  const bookingsRef = React.useRef<HTMLElement>(null)
  const historyRef = React.useRef<HTMLElement>(null)
  const codesRef = React.useRef<HTMLElement>(null)
  const paymentsRef = React.useRef<HTMLElement>(null)

  const dispatch = useAppDispatch()

  const { hasOption } = useSubscriptionOptions(subscription)

  const { isLoading, action: fetch } = useApiRequest(async () =>
    dispatch(setSubscriptionDetails(await commonObjectGet<SubscriptionDetails>(subscription.urls.details))),
  )

  React.useEffect(() => {
    fetch()

    return () => {
      dispatch(clearSubscriptionDetails())
    }
  }, [subscription.id])

  const copyValue = React.useMemo(() => {
    const url =
      subscription.type === 'WHOLESALE'
        ? NavigationPath.PackageWholesalePackagesWithPrams
        : NavigationPath.SubscriptionListWithParams
    return getDashboardAppUrl(url.replace(':id', String(subscription.id)))
  }, [subscription])

  const { product_name } = useSubscriptionTypeStrategyProvider(subscription.type)

  const isInitialBookingImprovement = subscription.type === 'BOOKING_IMPROVEMENT' && subscription.status === 'initial'

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        {product_name}
        <span className="text-semi-strong"> {subscription.number}</span>
        <CopyToClipboard className="uil-share-alt ml-1" value={copyValue} />
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {subscriptionDetails && (
            <>
              <div className="modal-details__tabs-wrapper">
                <SubscriptionDetailsTabs
                  dataRef={dataRef}
                  paymentsRef={paymentsRef}
                  transferRef={transferRef}
                  bookingsRef={bookingsRef}
                  codesRef={codesRef}
                />
              </div>

              <SubscriptionDetailsInformation subscriptionDetails={subscriptionDetails} ref={dataRef} />
              <div ref={paymentsRef as React.RefObject<HTMLDivElement>} />
              <Row>
                <Col md={subscription.type === 'DEFAULT' ? 6 : 12}>
                  <SubscriptionDetailsPayments subscriptionDetails={subscriptionDetails} />
                </Col>
                {subscription.type === 'DEFAULT' && (
                  <Col md={6}>
                    <SubscriptionDetailsTouristVouchers subscriptionDetails={subscriptionDetails} />
                  </Col>
                )}
              </Row>
              <SubscriptionDetailsCodes subscriptionDetails={subscriptionDetails} ref={codesRef} />
              {!!subscriptionDetails.entrance_tickets.length && hasOption('zoo_borysew') && (
                <PackageDetailsEntranceCodes
                  title="Lista biletów do obiektu ZOO Borysew"
                  entranceTickets={subscriptionDetails.entrance_tickets.filter(ticket => ticket.kind === 'zoo_borysew')}
                  codesActive={subscriptionDetails.status === 'confirmed'}
                  downloadUrl={subscriptionDetails.urls.entrance_tickets_download}
                />
              )}
              {!!subscriptionDetails.entrance_tickets.length && hasOption('suntago') && (
                <PackageDetailsEntranceCodes
                  title="Lista biletów do obiektu Suntago"
                  entranceTickets={subscriptionDetails.entrance_tickets.filter(ticket => ticket.kind === 'suntago')}
                  codesActive={subscriptionDetails.status === 'confirmed'}
                  downloadUrl={subscriptionDetails.urls.entrance_tickets_download}
                />
              )}
              <SubscriptionDetailsBookings subscriptionDetails={subscriptionDetails} ref={bookingsRef} />
              <SubscriptionDetailsPaymentsTransfer subscriptionDetails={subscriptionDetails} ref={transferRef} />

              <Row>
                {notifications && (
                  <Col md={6}>
                    <ProductEmailNotifications
                      onSuccess={fetch}
                      history={subscriptionDetails.notifications}
                      notificationEmails={notifications}
                      url={subscriptionDetails.urls.notifications}
                      email={subscriptionDetails.email}
                      readOnly={subscriptionDetails.read_only || !user.hasPerm(crud_permissions.update)}
                    />
                  </Col>
                )}
                <Col md={6}>
                  <HistoryBox history={subscriptionDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6}>
            {subscriptionDetails &&
              !subscriptionDetails.read_only &&
              user.hasPerm(crud_permissions.destroy) &&
              !isInitialBookingImprovement && (
                <SubscriptionDetailsCancel
                  subscriptionDetails={subscriptionDetails}
                  toggleIsVisible={toggleIsVisible}
                />
              )}
          </Col>
          <Col md={6} className="text-right">
            <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}

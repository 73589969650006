import * as React from 'react'
import { Button, Col, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm, useWatch } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput, FormPlain } from '@hyper/forms'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { PackageDetails, PackageType } from '@models/package'
import * as R from 'ramda'
import { createSelectOption, formatPrice, formatPriceShort } from '@helpers/utils'
import { useDebounce } from 'rooks'
import { calculatePrice } from '@api/package'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageDetails } from '@store/slices/package-slice'
import { extractInnerRef } from '@helpers/forms'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  packageDetails: PackageDetails
}

interface FormInputs {
  apartments_mountains: number
  apartments_sea: number
  apartments_universal: number
  houses_mountains: number
  houses_sea: number
  houses_universal: number
  with_zoo_borysew_tickets: boolean
  zoo_borysew_tickets_single_price_brutto: string
  with_suntago_tickets: boolean
  suntago_tickets_single_price_brutto: string
}

export const PackageParamsEditModal: React.FC<Props> = ({ packageDetails, toggleIsVisible }) => {
  const { prices } = usePackageAppData()

  const methods = useForm<FormInputs>({
    defaultValues: R.pick<FormInputs, keyof FormInputs>(
      [
        'apartments_mountains',
        'apartments_sea',
        'apartments_universal',
        'houses_mountains',
        'houses_sea',
        'houses_universal',
        'with_zoo_borysew_tickets',
        'zoo_borysew_tickets_single_price_brutto',
        'with_suntago_tickets',
        'suntago_tickets_single_price_brutto',
      ],
      packageDetails,
    ),
  })

  React.useEffect(() => {
    if (!packageDetails.zoo_borysew_tickets_single_price_brutto) {
      methods.setValue(
        'zoo_borysew_tickets_single_price_brutto',
        prices.package_zoo_borysew_tickets_default_price_brutto,
      )
    }
  }, [])

  const [priceBrutto, setPriceBrutto] = React.useState(0)

  const paramsToCalculate = useWatch({ control: methods.control })

  const { action: calculate } = useFormRequest(async () => {
    methods.clearErrors()

    setPriceBrutto(
      await calculatePrice({
        defaults_days: packageDetails.defaults_days,
        kind: { value: packageDetails.kind, label: packageDetails.kind },
        with_cleaning_option: packageDetails.with_cleaning_option,
        rent_towel_amount: packageDetails.rent_towel_amount,
        house_single_price: createSelectOption(
          formatPriceShort(packageDetails.house_single_price),
          packageDetails.house_single_price,
        ),
        apartment_single_price: createSelectOption(
          formatPriceShort(packageDetails.apartment_single_price),
          packageDetails.apartment_single_price,
        ),
        with_first_car_parking: packageDetails.with_first_car_parking,
        with_second_car_parking: packageDetails.with_second_car_parking,
        ...methods.getValues(),
        package_type: packageDetails.package_type.toUpperCase() as PackageType,
      }),
    )
  }, methods.setError)

  const debounceCalculatePrice = useDebounce(async () => {
    await calculate()
  }, 250)

  React.useEffect(() => {
    debounceCalculatePrice()
  }, [paramsToCalculate])

  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(updatePackageDetails(await commonObjectPatch(packageDetails.urls.params, payload)))
      addSuccessMessage('Sukces', `Pakiet ${packageDetails.number} został zmieniony!`)
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  const [includedZooTickets, includedSuntagoTickets] = useWatch({
    control: methods.control,
    name: ['with_zoo_borysew_tickets', 'with_suntago_tickets'],
  })

  const isHPI = packageDetails.package_type === 'HPI'

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Pakiet firmowy <span className="text-semi-strong">{packageDetails.number}</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12} className="font-16 fw-semi-bold mb-1">
            Domki
          </Col>
          <FormInput colSize={4} label="Morze" name="houses_sea" type="text" />
          <FormInput colSize={4} label="Góry" name="houses_mountains" type="text" />
          {isHPI && <FormInput colSize={4} label="Uniwersalne" name="houses_universal" type="text" />}
          <Col md={12} className="font-16 fw-semi-bold mb-1 mt-2">
            Apartamenty
          </Col>
          <FormInput colSize={4} label="Morze" name="apartments_sea" type="text" />
          <FormInput colSize={4} label="Góry" name="apartments_mountains" type="text" />
          {isHPI && <FormInput colSize={4} label="Uniwersalne" name="apartments_universal" type="text" />}

          {isHPI && (
            <>
              <FormPlain colSize={6} name="with_zoo_borysew_tickets" colClassName="mt-3">
                <CustomInput
                  className="mt-2"
                  type="checkbox"
                  id="with_zoo_borysew_tickets"
                  label="Dolicz bilety do Zoo Borysew"
                  {...extractInnerRef(methods.register('with_zoo_borysew_tickets'))}
                />
              </FormPlain>
              <FormInput
                disabled={!includedZooTickets}
                label={
                  <span>
                    Cena biletów <EntranceTicketPriceInformationTooltip />
                  </span>
                }
                colSize={6}
                name="zoo_borysew_tickets_single_price_brutto"
                step="0.01"
                placeholder="Cena biletów"
                type="number"
              />

              <FormPlain colSize={6} name="with_suntago_tickets" colClassName="mt-3">
                <CustomInput
                  className="mt-2"
                  type="checkbox"
                  id="with_suntago_tickets"
                  label="Dolicz bilety do Sutntago"
                  {...extractInnerRef(methods.register('with_suntago_tickets'))}
                />
              </FormPlain>
              <FormInput
                disabled={!includedSuntagoTickets}
                label={
                  <span>
                    Cena biletów <EntranceTicketPriceInformationTooltip />
                  </span>
                }
                colSize={6}
                name="suntago_tickets_single_price_brutto"
                step="0.01"
                placeholder="Cena biletów"
                type="number"
              />
            </>
          )}

          <hr className="w-100" />
          <FormPlain colSize={6} name="Cena brutto" formGroupClassName="mb-0">
            <label className="mb-0">Cena brutto</label>
            <strong className="text-semi-strong form-control border-0 d-inline">{formatPrice(priceBrutto || 0)}</strong>
          </FormPlain>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { CustomInput, Row } from 'reactstrap'
import { extractSelectOptionsValues, formatPrice, formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'
import { calculatePrice } from '@api/package'
import { PackageSummarySection, PackageSummarySectionDataRow } from './package-summary-section'
import { extractInnerRef } from '@helpers/forms'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { PackageType } from '@models/package'
import { GuestWalletScope } from '@models/booking'
import { gastroGenericScopes } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-scopes'

interface Props {
  type: PackageType
}
export const PackageCreateFormSummary = ({ type }: Props): JSX.Element => {
  const methods = useFormContext<PackageCreateFormInputs>()
  const [price, setPrice] = React.useState(0)

  const data = methods.getValues()

  const updatePrice = async () =>
    setPrice(await calculatePrice(extractSelectOptionsValues({ ...data, package_type: type })))

  React.useEffect(() => {
    updatePrice()
  }, [])

  const iconClass = value => (value ? 'uil-check text-secondary' : 'uil-times text-muted')

  const isHpi = type === 'hpi'

  const getScopeName = (value: GuestWalletScope) =>
    gastroGenericScopes.find(scope => scope.scope === value)?.scope_display

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={data.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={data.phone} />
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Parametry sprzedaży" className="col-12">
          <Row>
            {(!!data.houses_sea || !!data.houses_mountains || !!data.houses_universal) && (
              <div className="col-6">
                <PackageSummarySectionDataRow
                  title="Cena za domek:"
                  content={formatPriceShort(data.house_single_price?.value)}
                />
                <PackageSummarySectionDataRow
                  title="Ilość voucherów:"
                  content={
                    <div>
                      {!!data.houses_sea && <p className="mb-1">{data.houses_sea} (morze)</p>}
                      {!!data.houses_mountains && <p className="mb-1">{data.houses_mountains} (góry)</p>}
                      {!!data.houses_universal && <p className="mb-1">{data.houses_universal} (uniwersalne)</p>}
                    </div>
                  }
                />
              </div>
            )}

            {(!!data.apartments_sea || !!data.apartments_mountains || !!data.apartments_universal) && (
              <div className="col-6">
                <PackageSummarySectionDataRow
                  title="Cena za apartament:"
                  content={formatPriceShort(data.apartment_single_price?.value)}
                />
                <PackageSummarySectionDataRow
                  title="Ilość voucherów:"
                  content={
                    <div>
                      {!!data.apartments_sea && <p className="mb-1">{data.apartments_sea} (morze)</p>}
                      {!!data.apartments_mountains && <p className="mb-1">{data.apartments_mountains} (góry)</p>}
                      {!!data.apartments_universal && <p className="mb-1">{data.apartments_universal} (uniwersalne)</p>}
                    </div>
                  }
                />
              </div>
            )}
          </Row>
          {isHpi && !!data.gastro_voucher_amount && (
            <PackageSummarySectionDataRow
              titleFlex="col-3"
              title="Voucher gastronomiczny:"
              contentFlex="col-9"
              content={
                <div className="font-weight-normal">
                  <div className="mr-2">
                    Kwota: <strong>{formatPriceShort(data.gastro_voucher_amount)}</strong>
                  </div>
                  <div className="d-flex align-items-start">
                    Dostęp do:
                    <div className="ml-1 text-wrap">
                      {data.gastro_voucher_scopes.map((scope, index) => (
                        <strong key={scope}>
                          {getScopeName(scope)} {data.gastro_voucher_scopes[index + 1] ? ', ' : ''}
                        </strong>
                      ))}
                    </div>
                  </div>
                </div>
              }
            />
          )}
          <Row className="mt-2">
            <div className="col-6">
              <PackageSummarySectionDataRow
                title="Rodzaj pakietu:"
                content={
                  <>
                    {data.kind?.label}
                    {data.kind_version && ` (${data.kind_version?.label})`}
                  </>
                }
              />
              <PackageSummarySectionDataRow title="Cena brutto:" content={formatPrice(price)} />
              <PackageSummarySectionDataRow
                title="Kwota kaucji:"
                content={formatPriceShort(data.deposit_amount?.value)}
              />
              <PackageSummarySectionDataRow title="Data ważności:" content={toDefaultDateFormat(data.expire_after)} />
              <PackageSummarySectionDataRow
                title="Termin płatnośći kaucji:"
                content={data.deposit_payment_deadline.label}
              />
              <PackageSummarySectionDataRow
                title="Pakiet źródłowy:"
                content={data.package_source_type === 'DEFAULT' ? 'Brak' : data.package_source_type}
              />
            </div>
            <div className="col-6 d-flex flex-column">
              <IconWithText
                icon={classnames('font-16', iconClass(data.default_code_activate))}
                text="kody domyślnie aktywne"
                textClass="opacity-75"
                wrapperClassNames="d-block"
              />

              <IconWithText
                icon={classnames('font-16', iconClass(data.with_towels))}
                text={data.with_towels ? `dolicz ręczniki w ilości ${data.rent_towel_amount}` : 'dolicz ręczniki'}
                textClass="opacity-75"
                wrapperClassNames="d-block"
              />

              <IconWithText
                icon={classnames('font-16', iconClass(data.with_cleaning_option))}
                text="dolicz sprzątanie"
                textClass="opacity-75"
                wrapperClassNames="d-block"
              />

              {isHpi && (
                <>
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_free_cleaning))}
                    text="sprzątanie w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_free_towels))}
                    text="komplet ręczników w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_first_car_parking))}
                    text="dolicz pierwszy parking"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_second_car_parking))}
                    text="dolicz drugi parking"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_free_first_car_parking))}
                    text="pierwszy parking w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_free_second_car_parking))}
                    text="drugi parking w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />

                  <IconWithText
                    icon={classnames('font-16', iconClass(data.with_free_media_option))}
                    text="media w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.service_charge_included))}
                    text="opłata eksploatacyjna w cenie"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                  <IconWithText
                    icon={classnames('font-16', iconClass(data.allow_split_code))}
                    text="pozwól na dzielenie kodów"
                    textClass="opacity-75"
                    wrapperClassNames="d-block"
                  />
                </>
              )}
              <IconWithText
                icon={classnames('font-16', iconClass(data.with_zoo_borysew_tickets))}
                text={`dolicz bilety do Zoo Borysew w cenie ${formatPrice(
                  data.zoo_borysew_tickets_single_price_brutto,
                )}`}
                textClass="opacity-75"
                wrapperClassNames="d-block"
              />
              <IconWithText
                icon={classnames('font-16', iconClass(data.with_suntago_tickets))}
                text={`dolicz bilety do Suntago w cenie ${formatPrice(data.suntago_tickets_single_price_brutto)}`}
                textClass="opacity-75"
                wrapperClassNames="d-block"
              />
            </div>
          </Row>
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-6">
          <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'Brak'} />
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Potwierdzenie założenia pakietu" className="col-12">
          <CustomInput
            className="opacity-75 mt-2"
            type="checkbox"
            id="send_email"
            label="Przesyłane na adres e-mail"
            {...extractInnerRef(methods.register('send_email'))}
          />
        </PackageSummarySection>
      </Row>
    </div>
  )
}

import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { ClientDetailsModalBaseData } from '@modules/crm/clients/details-modal/base-data'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { getClientDetails, setClientDetails } from '@store/actions/crm-actions'
import { ClientDetailsModalBookings } from '@modules/crm/clients/details-modal/bookings/bookings'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ClientDetailsModalPackageVips } from '@modules/crm/clients/details-modal/packages-vip/package-vips'
import { ClientDetailsModalGastroCards } from '@modules/crm/clients/details-modal/gastro-cards/gastro-cards'
import { ClientDetailsModalSubscriptions } from '@modules/crm/clients/details-modal/subscriptions/subscriptions'
import { ClientDetailsModalClientProfile } from './client-profile'
import { ClientDetailsTabs } from '@modules/crm/clients/details-modal/tabs'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { NavigationPath } from '@models/routes'
import { getDashboardAppUrl } from '@helpers/utils'
import { ClientDetailsModalNotes } from '@modules/crm/clients/details-modal/notes'
import { BaseModalProps } from '@components/modals/types'
import { ClientDetailsModalSubscriptionContracts } from '@modules/crm/clients/details-modal/subscription-contracts/subscription-contracts'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { useCrmAppData } from '@modules/crm/hooks/use-crm-app-data'
import { ClientDetailsModalClientOptionPurchases } from '@modules/crm/clients/details-modal/client-option-purchases'
import { useDetailsModalUnmountParamsClear } from '@components/hooks/use-details-modal'
import { HistoryBox } from '@modules/promotions/common/history-box'

interface Props extends BaseModalProps {
  client: ClientDetails
}

export const ClientDetailsModal: React.FC<Props> = ({ toggleIsVisible, client }) => {
  const clientDetails = useAppSelector((state: RootState) => state.crmState.clientDetails)

  const dispatch = useAppDispatch()
  const dataRef = React.useRef<HTMLDivElement>(null)
  const historyAndNotesRef = React.useRef<HTMLDivElement>(null)
  const bookingsRef = React.useRef<HTMLDivElement>(null)
  const productsRef = React.useRef<HTMLDivElement>(null)

  const { client_notifications } = useCrmAppData()

  const { isLoading, action: fetchClientDetails } = useApiRequest(
    async () => await dispatch(getClientDetails(client.urls.details)),
  )

  useDetailsModalUnmountParamsClear(client.id)

  React.useEffect(() => {
    fetchClientDetails()

    return () => {
      dispatch(setClientDetails(undefined))
    }
  }, [])

  const copyValue = React.useMemo(
    () => getDashboardAppUrl(NavigationPath.CrmClientsWitParams.replace(':client_id', String(client.id))),
    [client],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        {clientDetails?.name || client.name || client.email} informacje o Gościu
        <CopyToClipboard className="uil-share-alt ml-1" value={copyValue} />
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {clientDetails && (
            <>
              <ClientDetailsTabs
                productsRef={productsRef}
                historyAndNotesRef={historyAndNotesRef}
                bookingsRef={bookingsRef}
                dataRef={dataRef}
              />
              <div ref={dataRef} />
              <Row>
                <ClientDetailsModalBaseData client={clientDetails} />
                <ClientDetailsModalClientProfile client={clientDetails} />
                <div ref={bookingsRef} />
                <ClientDetailsModalBookings client={clientDetails} />
                <div ref={productsRef} />
                <ClientDetailsModalSubscriptions client={clientDetails} />
                <ClientDetailsModalGastroCards client={clientDetails} />
                <ClientDetailsModalPackageVips client={clientDetails} />
                <ClientDetailsModalClientOptionPurchases client={clientDetails} />
                <ClientDetailsModalSubscriptionContracts client={clientDetails} />
                <Col md={6}>
                  <ProductEmailNotifications
                    ref={historyAndNotesRef}
                    url={clientDetails.urls.notifications || ''}
                    notificationEmails={client_notifications}
                    email={clientDetails.email}
                    onSuccess={fetchClientDetails}
                    history={clientDetails?.notifications || []}
                  />
                  <ClientDetailsModalNotes client={clientDetails} />
                </Col>
                <Col md={6}>
                  <HistoryBox history={clientDetails.history} />
                </Col>
                {/*<ClientHistoryBox history={clientDetails.history} />*/}
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Wróc do listy gości
        </Button>
      </ModalFooter>
    </>
  )
}

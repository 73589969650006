import * as React from 'react'
import { ReceptionBookingGuest } from '@models/booking'
import { BookingGuestFeedingOption, ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { dateWithShortTextMonth, formatDate, parseISODate } from '@helpers/date-helper'
import { StepGuestsGuestListRowRemove } from '@modules/reception/checkin/step-guests/step-guests-guest-list-row-remove'
import { StepGuestsGuestForm } from '@modules/reception/checkin/step-guests/step-guests-guest-form'
import { useDrag } from 'react-dnd'
import { StepGuestsItemStatus } from '@modules/reception/checkin/step-guests/step-guests-guest-form/step-guests-item-status'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useEditableTimeRange } from '@modules/reception/checkin/use-editable-time-range'
import { StepGuestsWalletScopesPreview } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-preview'
import { formatPhoneNumber } from '@helpers/utils'

interface StepGuestsGuestListRowProps {
  guest: ReceptionBookingGuest
  booking: ReceptionBookingDetails
  updateState: (booking: ReceptionBookingDetails) => void
  group: ReceptionBookingGroup
}

export const StepGuestsGuestListRow: React.FC<StepGuestsGuestListRowProps> = ({
  guest,
  booking,
  updateState,
  group,
}) => {
  const [guestEdit, setGuestEdit] = React.useState<undefined | ReceptionBookingGuest>(undefined)
  const formWrapper = React.useRef<HTMLDivElement>(null)

  const user = useAuthenticatedUser()
  const { isInEditAllowedWindow } = useEditableTimeRange(booking)

  const [, drag] = useDrag({
    type: 'BOOKING_GUEST',
    item: { guest },
  })

  const days = React.useMemo(() => {
    const dateFrom = parseISODate(guest.date_from || booking.date_from)
    const dateTo = parseISODate(guest.date_to || booking.date_to)

    if (
      (guest.date_from === booking.date_from && guest.date_to === booking.date_to) ||
      (guest.date_from === null && guest.date_to === null)
    ) {
      return 'cały pobyt'
    }

    return `${formatDate(dateFrom, 'dd MMM')} - ${formatDate(dateTo, dateWithShortTextMonth)}`
  }, [guest])

  const handleGuestEdit = () => (guestEdit ? setGuestEdit(undefined) : showGuestEditForm())

  React.useEffect(() => {
    formWrapper.current?.scrollIntoView({ block: 'center' })
  }, [guestEdit])

  const showGuestEditForm = () => setGuestEdit({ ...guest })

  const contactData = (contact: string, alternativeMsg: string) => (
    <div className="d-block mb-1 font-11">
      {contact || (
        <strong className="text-secondary cursor-pointer" onClick={showGuestEditForm}>
          {guest.type === 'adult' ? alternativeMsg : '-'}
        </strong>
      )}
    </div>
  )

  const isRemoveDisabled =
    booking.feeding_options.some(
      (option: BookingGuestFeedingOption) => option.guest_id === guest.id && (option.dinner || option.breakfast),
    ) &&
    !user.hasPerm(UserPermission.BookingGuestsCanRemoveWithFeeding) &&
    !isInEditAllowedWindow()

  return (
    <>
      <tr className="reservation-guests__table__row" ref={drag}>
        <td className="reservation-guests__table__column" width={350}>
          <div className="d-flex align-items-center">
            <i className="d-block uil uil-ellipsis-v mr-2 font-15 cursor-grab" />
            <div>
              <strong className="d-block mb-1 font-14">{guest.name}</strong>
              {contactData(formatPhoneNumber(guest.phone), 'uzupełnij numer telefonu')}
              {contactData(guest.email, 'uzupełnij adres e-mail')}
            </div>
          </div>
        </td>
        <td className="reservation-guests__table__column">
          <div className="mb-1">{guest.type_display}</div>
          Pobyt: <strong>{days}</strong>
        </td>
        <td className="reservation-guests__table__column" width={400}>
          <span>Dostęp do zakupów:</span>
          <StepGuestsWalletScopesPreview
            onStateUpdate={updateState}
            guest={guest}
            resortId={booking.resort_id}
            walletLimits={guest.wallet_limits}
          />
        </td>
        <td className="reservation-guests__table__column">
          {guest.type === 'adult' && (
            <>
              <span className="d-block mb-1">Zgody RODO</span>
              <StepGuestsItemStatus name="E-Mail" enabled={guest.rodo_email} />
              <StepGuestsItemStatus name="SMS" enabled={guest.rodo_phone} />
              <StepGuestsItemStatus name="Telefon" enabled={guest.rodo_phone_call} />
            </>
          )}
        </td>
        <td className="reservation-guests__table__column text-right">
          <i
            className="uil-edit-alt reservation-guests__table__column__action text-muted mr-1"
            role="guests-guest-edit"
            title="Edytuj gościa"
            onClick={handleGuestEdit}
          />
          <StepGuestsGuestListRowRemove updateState={updateState} guest={guest} disabled={isRemoveDisabled}>
            <i
              className="uil-trash-alt reservation-guests__table__column__action text-muted mr-1"
              title="Skasuj gościa"
            />
          </StepGuestsGuestListRowRemove>
        </td>
      </tr>
      <tr>
        {guestEdit && (
          <td colSpan={5} className="p-0">
            <StepGuestsGuestForm
              ref={formWrapper}
              updateState={updateState}
              guest={guestEdit}
              booking={booking}
              handleCancel={handleGuestEdit}
              group={group}
              isRemoveDisabled={isRemoveDisabled}
            />
          </td>
        )}
      </tr>
    </>
  )
}

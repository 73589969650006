import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBaseDataFixedAddButton = ({ booking }: Props): React.ReactNode => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  console.log(booking)

  React.useEffect(() => {
    const closeDropdown = () => {
      setIsDropdownOpen(false)
    }

    document.addEventListener('scroll', closeDropdown)

    return () => {
      document.removeEventListener('scroll', closeDropdown)
    }
  }, [])

  // TODO: Implement this component
  return (
    <div className="ml-auto mr-2 align-self-center">
      <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(state => !state)}>
        <DropdownToggle caret color="primary">
          <IconWithText icon="uil-plus font-14 lh-0" text="Dodaj" textClass="mr-3" />
        </DropdownToggle>
        <DropdownMenu container="body">
          <div className="d-flex p-2">
            <div>
              <strong className="text-secondary d-block mb-2">Dodaj do rachunku</strong>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-usd-circle lh-1" text="Opłatę klimatyczną" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-usd-circle lh-1" text="Kaucję" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-usd-circle lh-1" text="Wpłatę" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-usd-circle lh-1" text="Szkodę" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-usd-circle lh-1" text="Zakup na lokal" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
            </div>
            <div className="mx-3">
              <strong className="text-secondary d-block mb-2">Dodaj kody i rabaty</strong>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-tag-alt lh-1" text="Kod rabatowy" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-tag-alt lh-1" text="Kod pakietowy" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-tag-alt lh-1" text="Bon turystyczny" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-tag-alt lh-1" text="Voucher Gastro" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-plus lh-1" text="Inny rabat" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
            </div>
            <div>
              <strong className="text-secondary d-block mb-2">Dodaj ulepszenia</strong>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-restaurant lh-1" text="Wyżywienie" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-parking-square lh-1" text="Auto na parkingu" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-file-check-alt lh-1" text="Ubezpieczenie" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
              <DropdownItem className="border-bottom px-1">
                <IconWithText icon="uil-plus lh-1" text="Inne ulepszenie" textClass="font-12 fw-semi-bold" />
              </DropdownItem>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

import * as React from 'react'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm, useWatch } from 'react-hook-form'
import { GastroCardDetails } from '@models/promotions'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { updateGastroCardDetails } from '@store/slices/gastro-card-slice'
import { createGastroCards } from '@store/actions/gastro-card-actions'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { startOfToday } from 'date-fns'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from '@store/index'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { isGastroCouponDeclaredSellPriceValid } from '@modules/promotions/gastro-cards/utils'
import { createInvoiceData, InvoiceData } from '@components/products/product-invoice-card'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { BookingAutocompleteResponse } from '@components/booking-autosuggest'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { GastroProductInfo, GastroProductKind } from '@models/products'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { ClientUser } from '@models/clients'
import { GastroGenericProductDialogDataStep } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog-data-step'
import { GastroGenericProductDialogSummaryStep } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog-summary-step'
import { getGastroProductApiInfoUrl } from '@modules/promotions/gastro-generic-product/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useModal } from '@components/modals/use-modal'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import * as R from 'ramda'

export const GastroGenericProductPaymentMethodOptions = [
  createSelectOption('Przelewy 24 (online)', 'online'),
  createSelectOption('Gotówka / Karta', 'cash_card'),
]

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Parametry sprzedaży', fields: ['booking', 'declared_sell_price', 'payment_method'] },
  { step: 2, description: 'Podsumowanie sprzedaży' },
]

interface Props extends BaseModalProps {
  refresh?: () => void
  gastroCoupon?: GastroCardDetails
  kind: GastroProductKind
  gastroCouponClient?: ClientUser
}

export interface GastroGenericProductDialogFormInputs extends InvoiceData {
  scopes: string[]
  booking: BookingAutocompleteResponse | null
  client: ClientUser | null
  declared_sell_price: number
  payment_method: CustomReactSelectOption | null
  sell_date: Date
  auto_cancel_after_date: Date | null
  required_payment_date: Date | null
  active_after: Date | null
  expire_after: Date | null
  seller: CustomReactSelectOption | null
  reason_for_creation: CustomReactSelectOption | null
  source_marketing?: CustomReactSelectOption
}

export const GastroGenericProductDialog: React.FC<Props> = ({
  kind,
  toggleIsVisible,
  gastroCoupon,
  gastroCouponClient,
  refresh,
}) => {
  const [step, setStep] = React.useState(1)
  const [isNextStepDisabled, setIsNextStepDisabled] = React.useState(false)
  const [gastroProductInfo, setGastroProductInfo] = React.useState<GastroProductInfo | null>(null)

  const gastroProduct = useGastroProductProvider(kind)
  const { urls } = useAppData()

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const [showDetailsModal] = useModal('GastroCardDetailsDialog', {})

  const user = useAuthenticatedUser()
  const sellersOptions = useSellersOptions()
  const sources = useGroupedSources()

  const methods = useForm<GastroGenericProductDialogFormInputs>({
    defaultValues: {
      sell_date: parseISODate(gastroCoupon?.sell_date) ?? startOfToday(),
      payment_method: gastroProduct.allow_pay_by_cash
        ? makeDefaultSelectOption(GastroGenericProductPaymentMethodOptions, gastroCoupon?.payment_method) ??
          GastroGenericProductPaymentMethodOptions[0]
        : createSelectOption('Przelewy 24 (online)', 'online'),
      seller:
        makeDefaultSelectOption(sellersOptions, gastroCoupon?.seller_id) ??
        createSelectOption(user.seller?.name, user.seller?.id),
      declared_sell_price: gastroCoupon?.declared_sell_price,
      source_marketing: sources.find(row => row.value === gastroCoupon?.source_marketing),
      client: gastroCoupon?.client ?? gastroCouponClient,
      booking: gastroCoupon?.booking,
      invoice_type: 'company',
      auto_cancel_after_date: parseISODate(gastroCoupon?.auto_cancel_after_date),
      required_payment_date: parseISODate(gastroCoupon?.required_payment_date),
      expire_after: parseISODate(gastroCoupon?.expire_after),
      active_after: parseISODate(gastroCoupon?.active_after),
      ...(gastroCoupon && createInvoiceData(gastroCoupon)),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (data: GastroGenericProductDialogFormInputs) => {
    const { booking, client, ...payload } = data

    const isGastroCardCompensation = kind === 'gastro_card_compensation'

    try {
      if (gastroCoupon) {
        dispatch(
          updateGastroCardDetails(
            await commonObjectPut<GastroCardDetails>(gastroCoupon.urls.details, extractSelectOptionsValues(payload)),
          ),
        )
        addSuccessMessage('Sukces', 'Dane zostały zmienione.')
      } else {
        const resultAction = await dispatch(
          createGastroCards({
            ...R.omit(
              ['active_after', ...(isGastroCardCompensation ? [] : ['expire_after'])],
              extractSelectOptionsValues(payload),
            ),
            ...(gastroProduct.assign_type === 'booking' && { booking: booking?.id }),
            ...(gastroProduct.assign_type === 'client' && { client: client?.id }),
            scopes: data.scopes ? data.scopes.filter(Boolean) : undefined,
            kind: 'gastro_coupon',
            gastro_product_kind: gastroProduct.kind,
          }),
        )

        const gastroCard = unwrapResult(resultAction)
        showConfirmationPackageCreation(() => showDetailsModal(null, { gastroCard }))
      }

      refresh?.()
      toggleIsVisible()
    } catch (error) {
      console.log(error)
      showStepError(setStep, steps, error)
      throw error
    }
  }, methods.setError)

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => {
    if (
      isGastroCouponDeclaredSellPriceValid<GastroGenericProductDialogFormInputs>(
        'declared_sell_price',
        methods,
        gastroProduct.minimal_value,
      )
    ) {
      setStep(curr => curr + 1)
    }
  }

  const [booking, client, expire_after] = useWatch({
    control: methods.control,
    name: ['booking', 'client', 'expire_after'],
  })

  const { action: fetchMultiplierFactor } = useApiRequest(async () => {
    const isBookingSelection = gastroProduct.assign_type === 'booking'
    const isClientSelection = gastroProduct.assign_type === 'client'

    const { declared_sell_price, expire_after } = methods.getValues()

    if (isBookingSelection && !booking) return
    if (isClientSelection && !client) return

    const isGastroCardCompensation = kind === 'gastro_card_compensation'

    const response = await commonObjectGet<GastroProductInfo>(getGastroProductApiInfoUrl(gastroProduct.kind, urls), {
      ...(isBookingSelection ? { booking: booking?.id } : { client: client?.id, declared_sell_price }),
      ...(isGastroCardCompensation && { expire_after }),
    })

    setGastroProductInfo(response)
  })

  React.useEffect(() => {
    fetchMultiplierFactor()
  }, [client, booking, step, formatDate(expire_after)])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        {gastroCoupon ? 'Edytuj' : 'Dodaj'} Voucher {gastroProduct.product_name}
      </ModalHeader>
      {!gastroCoupon && <ReceptionBookingCheckSteps step={step} steps={steps} />}
      <Form onSubmit={onSubmit} methods={methods}>
        <ModalBody className="pb-0">
          {step === 1 && (
            <GastroGenericProductDialogDataStep
              kind={kind}
              gastroCoupon={gastroCoupon}
              onSubmitDisabledChange={setIsNextStepDisabled}
              gastroProduct={gastroProduct}
              gastroProductInfo={gastroProductInfo}
            />
          )}
          {step === 2 && gastroProductInfo && (
            <GastroGenericProductDialogSummaryStep
              gastroProduct={gastroProduct}
              gastroProductInfo={gastroProductInfo}
            />
          )}
        </ModalBody>
        <ModalFooter className="d-block">
          <Row>
            <Col md={6} className="p-0">
              {step !== 1 && (
                <Button onClick={handlePrevStep} className="btn btn-light">
                  Wstecz
                </Button>
              )}
            </Col>
            <Col md={6} className="text-right p-0">
              {gastroCoupon || step === 2 ? (
                <SaveButton
                  role="submit"
                  labelSaving={gastroCoupon ? 'Zapisywanie...' : 'Sprzedawanie...'}
                  label={gastroCoupon ? 'Zapisz' : 'Sprzedaj'}
                  className="btn btn-green"
                  isSaving={isLoading}
                />
              ) : (
                <Button
                  onClick={handleNextStep}
                  className="btn btn-green"
                  role="next-step"
                  disabled={(!booking && !client) || isNextStepDisabled}
                >
                  Następny krok
                </Button>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </>
  )
}

import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { SubscriptionDetailsCodesRow } from '@modules/subscription/details/codes-row'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { SubscriptionDetailsCodesActions } from '@modules/subscription/details/codes-actions'
import * as R from 'ramda'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

const headerValues: TableHeaderValue<SubscriptionCode>[] = [
  { title: 'LP' },
  { title: 'Kod', sortField: 'code', search: true },
  { title: 'Podzielony?', sortField: 'is_splitted' },
  { title: 'Użyty?', sortField: 'is_used' },
  { title: 'Lokalizacja', sortField: 'localization_kind' },
  { title: 'Rodzaj', sortField: 'kind' },
  { title: 'Data ważności', sortField: 'expire_after' },
  { title: '' },
]

const defaultFilters: TableFilters = {
  search: '',
  ordering: '-is_used',
  page: 1,
  page_size: 9999,
}

export const SubscriptionDetailsCodes = React.forwardRef<HTMLElement, Props>(({ subscriptionDetails }, ref) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<SubscriptionCode>(
    subscriptionDetails.codes,
    subscriptionDetails.codes.filter(row => row.is_used || row.is_splitted).map(row => row.id),
  )
  const filteredRows = useFilteredRows<SubscriptionCode>(
    subscriptionDetails.codes,
    headerValues,
    String(filters.search),
  )
  const sortedCodes = useSortedRows<SubscriptionCode>(filteredRows, filters.ordering)

  const notUsedCode = React.useMemo(
    () => subscriptionDetails.codes.filter(row => !row.is_used || (row.is_used && row.splitted_used > 0)).length,
    [subscriptionDetails.codes],
  )

  const allowedSubscriptionCodeLocalizationConvert = R.sum(
    subscriptionDetails.options
      .filter(row => row.is_paid)
      .filter(row => row.kind === 'localization' || row.kind === 'localization_unlimited')
      .map(row => row.amount_left),
  )

  return (
    <Card innerRef={ref}>
      <CardBody>
        <span className="h4 text-secondary">Lista kodów</span>
        {subscriptionDetails.status !== 'confirmed' && (
          <sup className="badge bg-danger text-white ml-1 badge rounded-pill">
            Kody są nieaktywne (należy potwierdzić pakiet)
          </sup>
        )}

        <Row className="align-items-center">
          <Col className="col-auto col-md-8">
            <SubscriptionDetailsCodesActions
              clearSelectedRows={clearSelectedRows}
              subscription={subscriptionDetails}
              selectedRows={selectedRows}
            />
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-2" />
        </Row>
        {!!allowedSubscriptionCodeLocalizationConvert && (
          <p className="text-right">
            Możesz przekonwertować kodów {allowedSubscriptionCodeLocalizationConvert} z {notUsedCode}
          </p>
        )}
        <Table
          maxHeight="330px"
          className="table-hover w-100 mt-1"
          headerValues={headerValues}
          selectableHeader={selectableHeader}
          isLoading={false}
          showPagination={false}
          filters={filters}
          setFilters={setFilters}
        >
          {sortedCodes.map((subscriptionCode, index) => (
            <SubscriptionDetailsCodesRow
              index={index + 1}
              subscriptionCode={subscriptionCode}
              key={subscriptionCode.id}
              subscriptionDetails={subscriptionDetails}
              readOnly={subscriptionDetails.read_only}
            >
              {selectableCell(subscriptionCode)}
            </SubscriptionDetailsCodesRow>
          ))}
        </Table>
      </CardBody>
    </Card>
  )
})

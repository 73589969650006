import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'

interface Props {
  subscriptionDetails?: SubscriptionDetails
}

export const SubscriptionCreateFormAdditionalClient: React.FC<Props> = ({ subscriptionDetails }) => {
  const user = useAuthenticatedUser()
  const { setValue, formState, control } = useFormContext<SubscriptionCreateFormInputs>()

  const [additionalClients, setAdditionalClients] = React.useState<ClientUser[]>(
    subscriptionDetails?.clients.filter(row => row.id !== subscriptionDetails?.client_id) ?? [],
  )

  const baseClient = useWatch({ control, name: 'client' })

  React.useEffect(() => {
    setValue('clients', additionalClients)
  }, [additionalClients])

  const isClientEditDisabled =
    !!subscriptionDetails &&
    subscriptionDetails?.status !== 'initial' &&
    !user.hasPerm(UserPermission.SubscriptionCanChangeClient)

  const handleAddAdditionalClient = (client: ClientUser) => setAdditionalClients(client ? [client] : [])
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient: handleAddAdditionalClient })

  return (
    <ClientSelect
      setClient={handleAddAdditionalClient}
      client={additionalClients[0] ?? undefined}
      handleToggleClientDialog={handleToggleClientDialog}
      error={formState.errors.client}
      isDisabled={isClientEditDisabled}
      label="Dodatkowy Klient"
      emailsToOmit={baseClient ? [baseClient.email] : []}
    />
  )
}

import * as React from 'react'
import ModalBody from 'reactstrap/lib/ModalBody'
import { Col, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { StepVerificationBookingInfo } from '@modules/reception/checkin/step-verification/step-verification-booking-info'
import { StepVerificationBookingDocuments } from '@modules/reception/checkin/step-verification/step-verification-booking-documents'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { ReceptionBookingCheck } from '../../common/reception-booking-check'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'

interface ReceptionBookingCheckInStepVerificationProps {
  nextStep: () => void
  booking: ReceptionBookingDetails
}

export interface ReceptionBookingCheckInStepVerificationFormInputs {
  id_card: string
  check_documents: boolean
  check_notes: boolean
}

export const ReceptionBookingCheckInStepVerification: React.FC<ReceptionBookingCheckInStepVerificationProps> = ({
  nextStep,
  booking,
}) => {
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const methods = useForm<ReceptionBookingCheckInStepVerificationFormInputs>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      id_card: booking.service_room_data?.id_card || '',
      check_notes: newUserPredicator(user),
      check_documents: newUserPredicator(user),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: ReceptionBookingCheckInStepVerificationFormInputs) => {
      const { id_card, ...payloadData } = payload

      const idCardChanged =
        !booking.service_room_data.id_card ||
        (booking.service_room_data?.id_card && booking.service_room_data.id_card !== id_card)
      await dispatch(
        updateReceptionBookingDetails(
          await commonObjectPatch<ReceptionBookingDetails>(booking.urls.check_in_step_verification, {
            ...payloadData,
            ...(idCardChanged && { id_card }),
          }),
        ),
      )

      nextStep()
    },
    methods.setError,
  )
  const reminders: ReceptionReminder[] = [
    {
      id: 'check_documents',
      isClosable: true,
      children: (
        <>
          Poproś Gościa o <strong>dowód osobisty i sprawdź</strong> czy dane podane w rezerwacji
          <br />
          <strong>imię, nazwisko, numer dowodu są poprawne.</strong>
        </>
      ),
    },
  ]

  if (booking.notes.length) {
    reminders.push({
      id: 'check_notes',
      isClosable: true,
      children: (
        <>
          <strong>Przed rozpoczęciem meldowania</strong> zapoznaj się ze <strong>wszystkimi notatkami</strong> do
          rezerwacji.
        </>
      ),
    })
  }

  React.useEffect(() => {
    methods.setValue('id_card', booking.service_room_data?.id_card || '')
  }, [booking.service_room_data?.id_card])

  return (
    <FormProvider {...methods}>
      <form
        className="reception__booking-check-in__verification_step"
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="off"
        role="step-verification"
      >
        <ModalBody>
          <h4>1. Weryfikacja rezerwacji</h4>

          <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />

          <Row className="mt-3">
            <Col md={5}>
              <StepVerificationBookingDocuments booking={booking} />
            </Col>
            <Col md={7}>
              <StepVerificationBookingInfo booking={booking} />
            </Col>
          </Row>
        </ModalBody>
        <ReceptionBookingCheck nextStep={nextStep} isSaving={isLoading} />
      </form>
    </FormProvider>
  )
}

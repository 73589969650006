import * as React from 'react'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { Card, CardBody } from 'reactstrap'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'
import { ClientUser } from '@models/clients'
import { formatPhoneNumber } from '@helpers/utils'

interface ClientData {
  id: number
  name: string
  email: string
  phone: string
  city: string
  postcode: string
  street: string
}

interface Props {
  canViewClientDetails?: boolean
  clientData: ClientData
  label?: string
}

export const ProductClientCard: React.FC<Props> = ({ clientData, canViewClientDetails, label = 'Dane klienta' }) => (
  <Card>
    <CardBody>
      <h4 className="mt-0 text-secondary mb-3">{label}</h4>
      <table className="table table-sm mb-0">
        <tbody>
          <SubscriptionDetailsInformationRow name="Imię i Nazwisko">
            {canViewClientDetails ? (
              <Link
                className="text-primary text-semi-strong"
                to={NavigationPath.CrmClientsWitParams.replace(':client_id', String(clientData.id))}
              >
                {clientData.name}
              </Link>
            ) : (
              clientData.name
            )}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Adres e-mail">{clientData.email}</SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Numer telefonu">
            {formatPhoneNumber(clientData.phone)}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Miasto">
            {clientData.city} {clientData.postcode}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Ulica">{clientData.street}</SubscriptionDetailsInformationRow>
        </tbody>
      </table>
    </CardBody>
  </Card>
)

export const createProductCardClient = (clientUser: ClientUser): ClientData => ({
  id: clientUser.id,
  name: clientUser.name,
  email: clientUser.email,
  phone: clientUser.phone,
  city: clientUser.profile?.city ?? '-',
  postcode: clientUser.profile?.postcode ?? '-',
  street: clientUser.profile?.street ?? '-',
})

import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingCheckInStepVerificationFormInputs } from '@modules/reception/checkin/step-verification/index'
import { FormInput } from '@hyper/forms'

interface StepVerificationBookingDocumentsProps {
  booking: ReceptionBookingDetails
}

export const StepVerificationBookingDocuments: React.FC<StepVerificationBookingDocumentsProps> = ({ booking }) => {
  const { setValue, control } = useFormContext<ReceptionBookingCheckInStepVerificationFormInputs>()

  const [lastValue, setLastValue] = React.useState<string | null>(null)
  const [isEdit, setIsEdit] = React.useState(false)

  const formIdCardValue = useWatch({ control, name: 'id_card' })

  const handleEditClick = () => {
    setValue('id_card', '')
    setLastValue(formIdCardValue)
    setIsEdit(true)
  }

  const handleAbortEdit = () => {
    setValue('id_card', lastValue ?? booking.service_room_data.id_card)
    setIsEdit(false)
  }

  const handleConfirmEdit = () => {
    setIsEdit(false)
  }

  return (
    <div className="d-flex align-items-center col-11">
      <FormInput
        disabled={!!booking.service_room_data.id_card && !isEdit}
        colClassName="px-0"
        label={`Numer dowodu Gościa (${booking.name})`}
        inputClassName="reception__booking-check-in__verification_step__input"
        name="id_card"
      />
      {isEdit ? (
        <>
          <i className="uil-times text-default font-15 cursor-pointer mt-1 ml-2" onClick={handleAbortEdit} />
          <i className="uil-check text-default font-15 cursor-pointer mt-1 ml-1" onClick={handleConfirmEdit} />
        </>
      ) : (
        booking.service_room_data.id_card && (
          <i className="uil-pen text-default font-14 cursor-pointer mt-1 ml-2" onClick={handleEditClick} />
        )
      )}
    </div>
  )
}

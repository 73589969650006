import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PackageDetailsCodesRow } from '@modules/package/details/codes/row'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { LocalizationKind, PackageCode, PackageDetails } from '@models/package'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { PackageDetailsCodesDownload } from '@modules/package/details/codes/download'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { PackageDetailsCodesOptions } from '@modules/package/details/codes/table-options'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  packageDetails: PackageDetails
  localizationKind: LocalizationKind
}

const headerValues: TableHeaderValue<PackageCode>[] = [
  { title: 'LP' },
  { title: 'Kod', sortField: 'code', search: true },
  { title: 'Rodzaj', sortField: 'kind' },
  { title: 'Imię, nazwisko', sortField: 'name', search: true },
  { title: 'Opis' },
  { title: 'Ilość ręczników', sortField: 'towel_amount' },
  { title: 'Aktywny?', sortField: 'is_active' },
  { title: 'Sprzątanie?', sortField: 'with_cleaning_option' },
  { title: 'Podzielony?', sortField: 'is_splitted' },
  { title: 'Użyty?', sortField: 'is_used' },
  { title: '' },
]

const defaultFilters: TableFilters = {
  search: '',
  ordering: '-is_used',
  page: 1,
  page_size: 9999,
}

export const PackageDetailsCodesList: React.FC<Props> = ({ packageDetails, localizationKind }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const user = useAuthenticatedUser()

  const setValue = (_, search) => setFilters({ ...filters, search })

  const localizationKindFilteredRows = React.useMemo(
    () => packageDetails.codes.filter(code => code.localization_kind === localizationKind),
    [packageDetails.codes, localizationKind],
  )

  const filteredRows = useFilteredRows<PackageCode>(localizationKindFilteredRows, headerValues, String(filters.search))
  const sortedCodes = useSortedRows<PackageCode>(filteredRows, filters.ordering)
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<PackageCode>(sortedCodes)

  const canRemoveCodes =
    packageDetails.status === 'initial' &&
    packageDetails.package_type === 'HPI' &&
    user.hasPerm(UserPermission.PackageHpiCanRemoveCode)

  const sectionName = {
    mountains: 'Lista kodów - Góry',
    sea: 'Lista kodów - Morze',
    universal: 'Lista kodów - Uniwersalne',
  }[localizationKind]

  return (
    <Card data-testid={`package-details-codes-list-${localizationKind}`}>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-8">
            <span className="h4 text-secondary">{sectionName}</span>
            {packageDetails.status !== 'confirmed' && (
              <BadgeWithIcon
                icon="uil-ban"
                variant="danger-dark"
                title="Kody są nieaktywne (należy potwierdzić pakiet)"
                className="ml-1 badge rounded-pill"
              />
            )}
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-2" />
          {canRemoveCodes && (
            <Col md={12} className="mb-2">
              <PackageDetailsCodesOptions
                clearSelectedRows={clearSelectedRows}
                selectedRows={selectedRows}
                packageDetails={packageDetails}
              />
            </Col>
          )}
        </Row>
        <Table
          maxHeight="340px"
          className="table-hover table-sm w-100"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          filters={filters}
          selectableHeader={canRemoveCodes ? selectableHeader : undefined}
          setFilters={setFilters}
        >
          {sortedCodes.map((packageCode, index) => (
            <PackageDetailsCodesRow
              index={index + 1}
              packageDetails={packageDetails}
              canRemoveCodes={canRemoveCodes}
              packageCode={packageCode}
              key={packageCode.id}
              selectableCell={selectableCell}
            />
          ))}
        </Table>
        <div className="text-right mt-3">
          <PackageDetailsCodesDownload localizationKind={localizationKind} packageDetails={packageDetails} />
        </div>
      </CardBody>
    </Card>
  )
}

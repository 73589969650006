import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Form } from '@hyper/forms/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { updateSubscriptionProductsBulk } from '@store/slices/subscription-slice'
import { FormSelect } from '@hyper/forms/form-select'

interface Props extends BaseModalProps {
  products: SubscriptionProduct[]
  clearSelectedRows: () => void
  type: SubscriptionType
}

interface FormInputs {
  status: null | CustomReactSelectOption
}

export const SubscriptionProductStatusModal: React.FC<Props> = ({
  toggleIsVisible,
  products,
  clearSelectedRows,
  type,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.subscription.subscription_products)

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(
        updateSubscriptionProductsBulk(
          await commonObjectPut<SubscriptionProduct[]>(url.replace('replace-me', type), {
            status: payload.status?.value,
            products: products.map(row => row.id),
          }),
        ),
      )
      addSuccessMessage()
      clearSelectedRows()
      toggleIsVisible()
    },

    methods.setError,
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader>Zmień status produktów</ModalHeader>
      <ModalBody>
        <FormSelect options={packageWholesaleProductsStatusOptions} name="status" label="Status" />
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}

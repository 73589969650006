import * as React from 'react'
import AsyncSelect from 'react-select/async'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'
import { OptionProps, SelectComponentsConfig, SingleValueProps } from 'react-select'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { HrWorker, HrWorkerDetails } from '@modules/hr/workers/models'
import { useDebounce } from 'rooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { makeDefaultSelectOption } from '@components/custom-react-select'
import { PersonalDocumentTypeOptions } from '@modules/hr/consts'

interface Props {
  onAddNewWorker: () => void
}

export const HrWorkersSearch = ({ onAddNewWorker }: Props): JSX.Element => {
  const { urls } = useAppData()
  const { setValue, control } = useFormContext()

  const { action: onSuggestionsFetchRequested } = useApiRequest(
    async (query: string, onDataFetch: (result: HrWorkerDetails[]) => void) => {
      const results = await commonObjectGet<HrWorkerDetails[]>(urls.hr.hr_worker_profile_extended_list, {
        search: query,
        skip_paginator: true,
      })

      onDataFetch(results.filter(worker => worker.is_active))
    },
  )

  const debouncedSuggestionsFetch = useDebounce(onSuggestionsFetchRequested, 500)

  const onChange = (data: HrWorker) => {
    setValue('selectedWorker', data || null)
    if (data) setValue('isFormVisible', false)
  }
  const value = useWatch({ control, name: 'selectedWorker' })

  return (
    <div className="app-search">
      <label className="mb-1 font-weight-bold">Pracownik</label>
      <div className="input-group">
        <AsyncSelect
          cacheOptions={false}
          styles={selectStyles}
          name="selectedWorker"
          isClearable
          loadOptions={debouncedSuggestionsFetch}
          loadingMessage={() => 'Pobieranie danych...'}
          placeholder="Wyszukaj pracownika"
          components={{ ...SearchBarSelectComponents, NoOptionsMessage }}
          onChange={onChange}
          value={value}
        />
        <span className="mdi mdi-magnify search-icon" />
        <div className="input-group-append">
          <button type="button" className="btn btn-primary" onClick={onAddNewWorker}>
            lub dodaj nowe konto
          </button>
        </div>
      </div>
    </div>
  )
}

const SearchBarSelectComponents: SelectComponentsConfig<any, false, any> = {
  Option: (props: OptionProps<HrWorker, false, any>) => (
    <div className="app-search__option" ref={props.innerRef} {...props.innerProps}>
      <span>
        {props.data.first_name} {props.data.second_name} {props.data.last_name}
        <small className="d-block mt-1 font-11">
          PESEL: {props.data.tax_id} <br />
          Dokument: {props.data.personal_id_number}{' '}
          {props.data.personal_id_number_type ? (
            <span>
              ({makeDefaultSelectOption(PersonalDocumentTypeOptions, props.data.personal_id_number_type)?.label})
            </span>
          ) : null}
          <br />
        </small>
      </span>
    </div>
  ),
  SingleValue: (props: SingleValueProps<HrWorker>) => (
    <div className="position-absolute ml-4">
      {props.data.first_name} {props.data.last_name}
    </div>
  ),
}

const NoOptionsMessage = () => <div className="text-center p-1">Brak opcji</div>

const selectStyles: StylesConfig = {
  menu: provided => ({ ...provided, zIndex: 99999 }),
  menuList: provided => ({ ...provided, paddingBlock: 0 }),
  valueContainer: provided => ({
    ...provided,
    display: 'flex',
    '&:hover': { cursor: 'text' },
  }),
  control: provided => ({
    ...provided,
    width: 270,
    borderRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': { borderColor: '#cccccc', boxShadow: 'none', cursor: 'text' },
    '&:focus': { borderColor: 'none', boxShadow: 'none' },
  }),
  placeholder: provided => ({
    ...provided,
    position: 'absolute',
    paddingLeft: 30,
    fontSize: '14px',
  }),
  input: provided => ({ ...provided, paddingLeft: 30 }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: provided => ({ ...provided, cursor: 'pointer' }),
  dropdownIndicator: () => ({ display: 'none' }),
}

import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails, SubscriptionOption } from '@modules/subscription/models'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import * as R from 'ramda'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props extends BaseModalProps {
  subscriptionCode: SubscriptionCode
  subscriptionDetails: SubscriptionDetails
}

interface FormInputs {
  kind: CustomReactSelectOption
}

export const SubscriptionCodeConvertModal: React.FC<Props> = ({
  subscriptionCode,
  toggleIsVisible,
  subscriptionDetails,
}) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      const { expiration_extension, convert } = subscriptionCode.urls

      const url = payload.kind.value === 'expiration_extension' && expiration_extension ? expiration_extension : convert

      dispatch(updateSubscriptionDetails(await commonObjectPost(url, extractSelectOptionsValues(payload))))
      addSuccessMessage('Sukces', `Kod ${subscriptionCode.code} został skonwertowany!`)
      toggleIsVisible()
    },
    methods.setError,
    { nonFieldErrorsAs: 'kind' },
  )

  const canExtend = (filter: (option: SubscriptionOption) => boolean) =>
    R.sum(subscriptionDetails.options.filter(filter).map(row => row.amount_left)) > 0

  const canConvertKindPlatinum =
    !subscriptionCode.is_splitted && canExtend(row => row.kind === 'single_code_kind_convert_to_platinum')
  const canConvertKindGold =
    !subscriptionCode.is_splitted && canExtend(row => row.kind === 'single_code_kind_convert_to_gold')
  const canConvertKindSilver =
    !subscriptionCode.is_splitted && canExtend(row => row.kind === 'single_code_kind_convert_to_silver')
  const canConvertLocalization = canExtend(row => row.kind === 'localization')
  const canExtendExpirationDate =
    canExtend(row => row.kind === 'expiration_extension') && subscriptionCode.urls.expiration_extension

  const canUpgradeToPurple =
    !subscriptionCode.is_splitted && canExtend(row => row.kind === 'upgrade_to_purple' && row.is_paid)

  const options = [
    ...(canConvertKindPlatinum
      ? [createSelectOption('Konwertuj na platynowy', 'single_code_kind_convert_to_platinum')]
      : []),
    ...(canConvertKindGold ? [createSelectOption('Konwertuj na złoty', 'single_code_kind_convert_to_gold')] : []),
    ...(canConvertKindSilver ? [createSelectOption('Konwertuj na srebrny', 'single_code_kind_convert_to_silver')] : []),
    ...(canConvertLocalization ? [createSelectOption('Zmiana lokalizacji', 'localization')] : []),
    ...(canUpgradeToPurple ? [createSelectOption('Dostęp do lokali specjalnych', 'upgrade_to_purple')] : []),
    ...(canExtendExpirationDate
      ? [createSelectOption('Jednorazowe przedłużenie ważności kodu', 'expiration_extension')]
      : []),
  ]

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Konwersja kodu <span className="text-semi-strong">{subscriptionCode.code}</span>
      </ModalHeader>
      <ModalBody>
        <FormSelect options={options} name="kind" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

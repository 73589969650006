import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { formatPrice, getById } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { HrPosition } from '@modules/hr/common/models'
import { AnnexedFields } from '@modules/hr/agreement/models'
import { AgreementAnnexChange } from '@modules/hr/agreement/details/annexes/annex-change'

interface Props {
  positionData: Partial<HrPosition> | undefined
  isReadonly: boolean
  onEdit?: () => void
  annexedFields?: AnnexedFields
  agreementId: string | number
  title?: string
  showOriginalValue?: boolean
}

export const HrPositionDetails = React.forwardRef<HTMLElement, Props>(
  (
    { isReadonly, positionData, onEdit, annexedFields, agreementId, showOriginalValue, title = 'Dane stanowiska' },
    ref,
  ) => {
    const { companies, job_positions, agents } = useHrAppData()
    const { resorts } = useAppData()

    const company = getById(companies, positionData?.company)
    const jobPosition = getById(job_positions, positionData?.job_position)
    const agent = getById(agents, positionData?.agent)
    const agreementResorts = positionData?.resorts
      ? positionData?.resorts.map(resort => getById(resorts, resort)?.name).join(', ')
      : '-'

    return (
      <Card innerRef={ref}>
        <CardBody>
          <ProductCardTitle title={title} isEditAllowed={!isReadonly} onEdit={onEdit} />
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow name="Resort">{agreementResorts}</SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Spółka">
                {company?.name ?? '-'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Stanowisko">
                {jobPosition?.name ?? '-'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow
                name={
                  <span>
                    Stawka godzinowa <small>(brutto)</small>
                  </span>
                }
              >
                <div className="d-flex align-items-center justify-content-end">
                  {positionData?.salary_out_of_range && (
                    <IconWithTooltip
                      color="text-danger"
                      icon="uil-exclamation-circle font-15 mr-1"
                      tooltipId="salary-out-of-range-tooltip"
                      tooltipPlacement="right"
                      tooltipMessage={
                        <span className="font-11 text-left">Stawka jest poza zakresem dla wybranego stanowiska.</span>
                      }
                    />
                  )}
                  <AgreementAnnexChange
                    showOriginalValue={showOriginalValue}
                    id={`hourly-rate-${agreementId}`}
                    change={annexedFields?.hourly_rate}
                    originalValue={positionData?.hourly_rate}
                    formatter={formatPrice}
                  />
                </div>
              </SubscriptionDetailsInformationRow>

              <SubscriptionDetailsInformationRow name="Data rozpoczęcia umowy">
                {positionData?.date_of_start ? toDefaultDateFormat(positionData?.date_of_start) : '-'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data zakończenia umowy">
                <AgreementAnnexChange
                  change={annexedFields?.date_of_expiration}
                  originalValue={positionData?.date_of_expiration}
                  formatter={toDefaultDateFormat}
                  id={`date-of-expiration-${agreementId}`}
                />
              </SubscriptionDetailsInformationRow>

              <SubscriptionDetailsInformationRow name="Pełnomocnik">
                {agent?.full_name || '-'}
              </SubscriptionDetailsInformationRow>

              <SubscriptionDetailsInformationRow name="Zakres obowiązków">
                <AgreementAnnexChange
                  change={annexedFields?.responsibilities}
                  originalValue={jobPosition?.responsibilities}
                  id={`responsibilities-${agreementId}`}
                  showOriginalValue={showOriginalValue}
                  formatter={value => <div className="text-pre-wrap">{value}</div>}
                />
              </SubscriptionDetailsInformationRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    )
  },
)

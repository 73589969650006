import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useToggle } from '@components/hooks/use-toggle'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationGuestIdCard = ({ booking }: Props): React.ReactNode => {
  const [idCard, setIdCard] = React.useState<string | null>(null)
  const [isIdCardVisible, toggleIsIdCardVisible] = useToggle(false)
  const user = useAuthenticatedUser()

  const { action: toggleCardVisibility, isLoading } = useApiRequest(async () => {
    if (!isIdCardVisible) {
      const { id_card } = await commonObjectGet<{ id_card: string }>(booking.urls.show_id_card, {
        show: !isIdCardVisible,
      })
      setIdCard(id_card)
    } else {
      setIdCard(null)
    }

    toggleIsIdCardVisible()
  })

  return (
    <strong className="d-block">
      {booking.service_room_data.id_card ? (
        <>
          <span>{isIdCardVisible ? idCard : booking.service_room_data.id_card}</span>
          {user.hasPerm(UserPermission.BookingCanViewIdCard) && (
            <IconWithText
              icon={isIdCardVisible ? 'uil-eye-slash' : 'uil-eye'}
              text={isIdCardVisible ? 'Ukryj dane' : 'Pokaż dane'}
              textClass="font-11 fw-semi-bold"
              wrapperClassNames="ml-2"
              onClick={toggleCardVisibility}
              isDisabled={isLoading}
            />
          )}
        </>
      ) : (
        <span className="fw-semi-bold">brak</span>
      )}
    </strong>
  )
}

import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { FormInput } from '@hyper/forms'
import { GastroGenericScopes } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-scopes'
import { useForm, useWatch } from 'react-hook-form'
import { GuestWalletScope } from '@models/booking'
import { Form } from '@hyper/forms/form'

export interface ProductGastroCardConfigurationFormInputs {
  scopes: GuestWalletScope[]
  declared_sell_price: number
}

interface Props extends BaseModalProps {
  onClose: (data: null | ProductGastroCardConfigurationFormInputs) => void
  scopes: GuestWalletScope[]
  declared_sell_price: number
}

export const ProductGastroCardConfigurationModal: React.FC<Props> = ({
  toggleIsVisible,
  onClose,
  scopes = [],
  declared_sell_price = 0,
}) => {
  const methods = useForm<ProductGastroCardConfigurationFormInputs>({
    defaultValues: { scopes, declared_sell_price },
  })

  const [formScopes, price] = useWatch({
    control: methods.control,
    name: ['scopes', 'declared_sell_price'],
  })

  const isValid = !!formScopes.some(Boolean) && price > 0

  const onSubmit = () => {
    onClose(isValid ? methods.getValues() : null)
    toggleIsVisible()
  }

  const handleClose = () => {
    const hasPreviousValues = scopes.length || declared_sell_price > 1
    if (!isValid && !hasPreviousValues) onClose(null)
    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalHeader toggle={handleClose}>Skonfiguruj voucher gastronomiczny</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput
            colSize={4}
            placeholder={`min. ${1} zł`}
            label="Kwota"
            name="declared_sell_price"
            type="currency"
          />
          <GastroGenericScopes />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={handleClose}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={false} disabled={!isValid} />
      </ModalFooter>
    </Form>
  )
}

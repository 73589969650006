import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { RootState } from '@store/index'
import {
  BookingOption,
  BookingOptionDetails,
  BookingOptionsFiltersParams,
} from '@modules/promotions/booking-options/model'
import { createApiTag, provideListTags } from '@api/query-helper'
import { ClientOptionPrice } from '@models/clients'

export const BOOKING_OPTIONS_TAG = 'bookingOptions'
export const BOOKING_OPTIONS_TAG_IDS = {
  LIST: 'LIST',
  DETAILS: 'DETAILS',
}

export const bookingOptionsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'bookingOptionsApi',
  tagTypes: [BOOKING_OPTIONS_TAG],
  endpoints: builder => ({
    getAvailableClientOptions: builder.query<ClientOptionPrice[], void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.client_options.client_options_available_options,
      })),
    }),
    getBookingOptionsAggregation: builder.query<BookingOption[], BookingOptionsFiltersParams>({
      queryFn: apiQueryFunction((state: RootState, data: BookingOptionsFiltersParams) => ({
        params: extractSelectOptionsValues({ ...data, option_kinds: data.option_kinds || undefined, page_size: 1 }),
        method: 'GET',
        url: state.appState.appData.urls.client_options.client_options_purchases,
        responseTransformer: (response: PaginationResponseWithAggregation<BookingOption, { price_brutto: string }>) =>
          response.aggregation,
      })),
    }),
    getBookingOptions: builder.query<BookingOption[], BookingOptionsFiltersParams>({
      queryFn: apiQueryFunction((state: RootState, data: BookingOptionsFiltersParams) => ({
        params: extractSelectOptionsValues({ ...data, option_kinds: data.option_kinds || undefined }),
        method: 'GET',
        url: state.appState.appData.urls.client_options.client_options_purchases,
        responseTransformer: (response: PaginationResponse<BookingOption>) => response.results,
      })),
      providesTags: provideListTags(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.LIST),
    }),
    getBookingOptionDetails: builder.query<BookingOptionDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({ method: 'GET', url })),
      providesTags: [createApiTag(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.DETAILS)],
    }),
    createBookingOption: builder.mutation<BookingOptionDetails, BookingOptionDetails>({
      invalidatesTags: [createApiTag(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.LIST)],
      queryFn: apiQueryFunction((state: RootState, data: BookingOptionDetails) => ({
        url: state.appState.appData.urls.client_options.client_options_purchases,
        method: 'POST',
        data,
      })),
    }),
    deleteBookingOption: builder.mutation<void, { url: string; reason: string }>({
      invalidatesTags: [createApiTag(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.LIST)],
      queryFn: apiQueryFunction((_, { url, reason }) => ({
        url,
        method: 'DELETE',
        data: { reason },
      })),
    }),
    updateBookingOption: builder.mutation<BookingOptionDetails, { url: string; data: Partial<BookingOptionDetails> }>({
      invalidatesTags: [
        createApiTag(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.LIST),
        createApiTag(BOOKING_OPTIONS_TAG, BOOKING_OPTIONS_TAG_IDS.DETAILS),
      ],
      queryFn: apiQueryFunction((_, { url, data }: { url: string; data: Partial<BookingOptionDetails> }) => ({
        url,
        method: 'PATCH',
        data,
      })),
    }),
  }),
})

export const {
  useGetBookingOptionsQuery,
  useGetBookingOptionsAggregationQuery,
  useGetBookingOptionDetailsQuery,
  useCreateBookingOptionMutation,
  useUpdateBookingOptionMutation,
  useGetAvailableClientOptionsQuery,
  useDeleteBookingOptionMutation,
} = bookingOptionsApi

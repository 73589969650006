import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionBookingCheckIn } from '@modules/reception/checkin/reception-booking-check-in'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingCheckInPreview } from '@modules/reception/checkin/reception-booking-check-in-preview'
import classname from 'classnames'
import { ReceptionBookingExtensionButton } from '@modules/reception/common/reception-booking-extension-button'

interface ReceptionToCheckInRowButtonsProps {
  booking: ReceptionBooking
}

export const ReceptionToCheckInRowButtons: React.FC<ReceptionToCheckInRowButtonsProps> = ({ booking }) => {
  const [isDefaultCheckinOpen, setIsDefaultCheckinOpen] = React.useState(false)
  const [isPreviewCheckinOpen, setIsPreviewCheckinOpen] = React.useState(false)

  const handleOnClick = React.useCallback(() => {
    if (booking.checked_in_online) {
      setIsPreviewCheckinOpen(prev => !prev)
      return
    }

    setIsDefaultCheckinOpen(prev => !prev)
  }, [booking.checked_in_online])

  return (
    <div>
      {isDefaultCheckinOpen && (
        <ReceptionBookingCheckIn booking={booking} onClose={() => setIsDefaultCheckinOpen(false)} />
      )}
      {isPreviewCheckinOpen && (
        <ReceptionBookingCheckInPreview booking={booking} onClose={() => setIsPreviewCheckinOpen(false)} />
      )}
      <div className="d-flex align-items-start justify-content-end">
        <div className="d-flex flex-column mx-1">
          <ButtonWithIcon
            color={booking.checked_in_online ? 'primary' : 'green'}
            btnClass="reception__bookings_table__row__checkin-button"
            type="button"
            handleClick={handleOnClick}
            icon={classname('uil-check font-16')}
            text={
              booking.reception_action === 'check-in-started'
                ? 'Meldowanie...'
                : booking.checked_in_online
                  ? 'Potwierdź'
                  : 'Zamelduj'
            }
          />
          {booking.reception_action === 'check-in-started' && (
            <div className="text-center mt-1 font-11 d-block">
              Użytkownik: <span className="fw-semi-bold">{booking.reception_user}</span>
            </div>
          )}
        </div>

        <ReceptionBookingExtensionButton booking={booking} />
      </div>
    </div>
  )
}

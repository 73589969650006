import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { Col, CustomInput } from 'reactstrap'
import { FormInput, FormInputProps, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { CustomInputProps } from 'reactstrap/es/CustomInput'

interface Props {
  optionFieldName: FieldPath<PackageCreateFormInputs>
  optionLabel: React.ReactNode
  amountInputProps: FormInputProps
  checkboxProps?: Partial<CustomInputProps>
}

export const PackageCreateOptionWithAmountField = ({
  optionFieldName,
  optionLabel,
  amountInputProps,
  checkboxProps,
}: Props): React.ReactNode => {
  const { register } = useFormContext<PackageCreateFormInputs>()

  return (
    <Col md={8} className="d-flex">
      <FormPlain name={optionFieldName} colClassName="px-0">
        <CustomInput
          className="mt-2"
          type="checkbox"
          id={optionFieldName}
          label={optionLabel}
          {...extractInnerRef(register(optionFieldName))}
          {...checkboxProps}
        />
      </FormPlain>
      <FormInput colClassName="pl-0" colSize={4} type="number" {...amountInputProps} />
    </Col>
  )
}

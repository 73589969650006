import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { kinds, promoTypes } from '@modules/promocode/enums'
import { CustomInput, Input, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { PromocodePrefixDialogFormInputs } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useRentAppDataQuery } from '@api/rent'
import { TypedQueryResult } from '@api/base'
import { RentAppData } from '@models/rent'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  resortsOptions: CustomReactSelectOption[]
}

export const PromocodePrefixDialogForm: React.FC<Props> = ({ resortsOptions }) => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
  } = useFormContext<PromocodePrefixDialogFormInputs>()
  const { accommodation_types: accommodationTypes } = useAppData()
  const { data: rentAppData } = useRentAppDataQuery() as TypedQueryResult<RentAppData>

  const [maxDaysEnabled, selectedAccommodationTypes] = useWatch({
    control,
    name: ['max_days_enabled', 'accommodation_types'],
  })

  const [isApartmentSelected, isHouseSelected] = selectedAccommodationTypes.reduce(
    (prev, curr) => [
      prev[0] || ACCOMMODATION_TYPES.APARTMENTS.includes(parseInt(curr, 10)),
      prev[1] || ACCOMMODATION_TYPES.HOUSES.includes(parseInt(curr)),
    ],
    [false, false],
  )

  const updateAccommodationTypeDeposits = (field, isSelected, defaultValueField) => {
    if (isSelected) {
      const defaultDepositAmount = rentAppData?.promocode[defaultValueField]
      if (defaultDepositAmount) setValue(field, defaultDepositAmount.toString())
    } else {
      setValue(field, undefined)
    }
  }

  useDidUpdateEffect(() => {
    updateAccommodationTypeDeposits('deposit_amount', isApartmentSelected, 'prefix_deposit_amount')
  }, [isApartmentSelected])

  useDidUpdateEffect(() => {
    updateAccommodationTypeDeposits('deposit_house_amount', isHouseSelected, 'prefix_deposit_house_amount')
  }, [isHouseSelected])

  return (
    <Row>
      <FormInput placeholder="Wpisz prefiks, dodaj - by oddzielić od kodów" colSize={6} label="Prefiks" name="value" />

      <FormInput placeholder="Wpisz nazwę prefiksu" colSize={6} label="Nazwa prefiksu" name="name" />

      <FormInput colSize={6} type="select" label="Typ prefiksu" name="kind">
        {kinds.map((row: [string, string]) => (
          <option key={row[0]} value={row[0]}>
            {row[1]}
          </option>
        ))}
      </FormInput>

      <FormInput colSize={6} type="select" label="Typ wartości rabatu" name="promo_type">
        {promoTypes.map((row: [string, string]) => (
          <option key={row[0]} value={row[0]}>
            {row[1]}
          </option>
        ))}
      </FormInput>

      <FormInput
        colSize={6}
        type="number"
        inputProps={{ step: 0.01 }}
        placeholder="Wpisz wartość rabatu"
        label="Wartość rabatu"
        name="promo_value"
      />

      <FormPlain name="max_days" colSize={6} label="Maksymalna liczba dni pobytu">
        <Input
          {...extractInnerRef(register('max_days'))}
          type="number"
          placeholder="Wpisz maksymalną ilość dni pobytu"
          disabled={!maxDaysEnabled}
          className={errors.max_days && 'is-invalid'}
        />
      </FormPlain>
      <FormSelect
        options={resortsOptions}
        name="resorts"
        label="Ośrodek"
        selectProps={{ isClearable: true, isSelectMulti: true }}
        formPlainProps={{ colSize: 12, formGroupClassName: 'mb-3' }}
      />
      <FormPlain name="max_days_enabled" colSize={6}>
        <CustomInput
          type="checkbox"
          id="max_days_enabled"
          label="Ogranicz liczbę dni pobytu"
          {...extractInnerRef(register('max_days_enabled'))}
        />
      </FormPlain>

      <FormPlain name="is_disposable" colSize={6}>
        <CustomInput
          type="checkbox"
          id="is_disposable"
          label="Kod jednorazowego użytku"
          {...extractInnerRef(register('is_disposable'))}
        />
      </FormPlain>

      <FormPlain name="accommodation_types" label="Rodzaj zakwaterowania">
        <div className="row mx-0">
          {accommodationTypes.map(accommodationType => (
            <CustomInput
              className="col-4"
              key={accommodationType.id}
              type="checkbox"
              id={`accommodation_types-${accommodationType.id}`}
              label={<span className="text-semi-strong cursor-pointer">{accommodationType.name}</span>}
              value={accommodationType.id}
              {...extractInnerRef(register('accommodation_types'))}
            />
          ))}
        </div>
      </FormPlain>

      <FormInput
        colSize={6}
        type="currency"
        label="Kwota kaucji za apartament"
        name="deposit_amount"
        disabled={!isApartmentSelected}
      />
      <FormInput
        colSize={6}
        type="currency"
        label="Kwota kaucji za domek"
        name="deposit_house_amount"
        disabled={!isHouseSelected}
      />

      <FormInput placeholder="Wpisz opis" type="textarea" label="Opis" name="description" inputProps={{ rows: 4 }} />
    </Row>
  )
}

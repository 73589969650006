import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ReceptionBookingImprovementSubscriptionModalVoucherRow } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal-voucher-row'
import { ReceptionBookingImprovementSubscriptionFormInputs } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal'
import { ClientUser } from '@models/clients'
import { ImprovementAvailability } from '@models/booking'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  subscriptionImprovementAvailability: ImprovementAvailability
}

export const ReceptionBookingImprovementSubscriptionModalForm = ({
  subscriptionImprovementAvailability,
}: Props): React.ReactNode => {
  const user = useAuthenticatedUser()
  const { control } = useFormContext<ReceptionBookingImprovementSubscriptionFormInputs>()
  const sellers = useSellersOptions()

  const { fields, append, remove, update } = useFieldArray({ control, name: 'vouchers' })

  const handleAppend = () => {
    append({ client: null, amount: 1 })
  }

  return (
    <>
      <h4 className="text-secondary fw-semi-bold mb-0">Dodane vouchery:</h4>
      <div className="d-flex flex-column">
        {fields.map((field, index) => (
          <ReceptionBookingImprovementSubscriptionModalVoucherRow
            subscriptionImprovementAvailability={subscriptionImprovementAvailability}
            key={field.id}
            index={index}
            onUpdate={(client: ClientUser | null) => update(index, { ...field, client })}
            onRemove={fields.length > 1 ? () => remove(index) : undefined}
          />
        ))}

        <ButtonWithIcon
          handleClick={handleAppend}
          icon="uil-plus font-16"
          text="Dodaj osobę zamawiającą"
          color="green"
          btnClass="btn my-2 col-4 mx-auto btn-flat"
        />
        <hr className="w-100" />
        <FormSelect
          options={sellers}
          name="seller"
          label="Sprzedawca"
          formPlainProps={{ colSize: 5, colClassName: 'px-0' }}
          selectProps={{
            isDisabled: !user.hasPerm(UserPermission.BookingCanChangeSellerInSubscriptionBookingImprovement),
          }}
        />
      </div>
    </>
  )
}

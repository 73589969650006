import * as React from 'react'
import { SubscriptionCreateFormClientData } from '@modules/subscription/create/steps/client-data'
import { SubscriptionCreateFormInformation } from '@modules/subscription/create/steps/information'
import { FormInput, FormPlain } from '@hyper/forms'
import { Row } from 'reactstrap'
import { SubscriptionDetails } from '@modules/subscription/models'
import DatePickerInput from '@components/date/date-picker-input'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionEditModalForm: React.FC<Props> = ({ subscriptionDetails }) => {
  const user = useAuthenticatedUser()

  return (
    <>
      <SubscriptionCreateFormClientData
        subscriptionDetails={subscriptionDetails}
        type={subscriptionDetails.type}
        initialClient={subscriptionDetails.client}
      />
      <div className="pb-2" />
      <SubscriptionCreateFormInformation subscriptionDetails={subscriptionDetails} />
      <Row>
        <FormInput name="created" label="Data utworzenia" colSize={6} />
        {subscriptionDetails && (
          <FormPlain name="auto_cancel_after_date" colSize={6}>
            <label>Auto anulowanie po</label>
            <DatePickerInput
              name="auto_cancel_after_date"
              isClearable={true}
              wrapperClassName="flex-grow-1"
              isDisabled={!user.hasPerm(UserPermission.PromotionsSubscriptionCanChangeAutoCancelAfterDate)}
            />
          </FormPlain>
        )}
      </Row>
    </>
  )
}

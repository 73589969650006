import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { formatPriceShort } from '@helpers/utils'
import { gastroGenericScopes } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-scopes'
import { GuestWalletScope } from '@models/booking'

interface Props {
  onClearVoucher: () => void
  onShowVoucher: () => void
}

export const PackageCreateGastroVoucherRow: React.FC<Props> = ({ onClearVoucher, onShowVoucher }) => {
  const { control } = useFormContext<PackageCreateFormInputs>()

  const [gastroVoucherScopes, gastroVoucherAmount] = useWatch({
    control,
    name: ['gastro_voucher_scopes', 'gastro_voucher_amount'],
  })

  const getScopeName = (value: GuestWalletScope) =>
    gastroGenericScopes.find(scope => scope.scope === value)?.scope_display

  if (!gastroVoucherAmount || !gastroVoucherScopes.length) return null

  return (
    <>
      <hr className="w-100" />
      <div className="d-flex align-items-center w-100">
        <strong className="col-3">Voucher gastronomiczny</strong>
        <div className="col-9 d-flex align-items-center">
          <div className="col-3 pl-0">Kwota: {formatPriceShort(gastroVoucherAmount)} </div>
          <div className="col-9 d-flex align-items-center">
            <div className="text-nowrap mr-2">Dostęp do:</div>
            <div>
              {gastroVoucherScopes.map(scope => (
                <span key={scope} className="badge bg-light-grey mx-1">
                  {getScopeName(scope)}
                </span>
              ))}
            </div>
            <div className="ml-auto d-flex flex-nowrap">
              <i className="uil-edit-alt cursor-pointer pr-1" onClick={onShowVoucher} />
              <i className="uil-trash-alt cursor-pointer" onClick={onClearVoucher} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import * as React from 'react'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'

type SubscriptionCodeRevertConversionKind = 'localization' | 'single_code_kind_convert' | 'upgrade_to_purple'

interface FormInputs {
  localization: CustomReactSelectOption
}

interface Props {
  subscriptionCode: SubscriptionCode
  kind: SubscriptionCodeRevertConversionKind
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionCodeConversionRevert = ({
  kind,
  subscriptionCode,
  subscriptionDetails,
}: Props): React.ReactNode => {
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const hasMixedLocalizations = React.useMemo(
    () =>
      [subscriptionDetails.local_sea, subscriptionDetails.local_universal, subscriptionDetails.local_mountains].filter(
        Boolean,
      ).length > 1,
    [kind],
  )

  const canSelectLocalization = kind === 'localization' && hasMixedLocalizations

  const { isLoading, action: revertConversion } = useApiRequest(
    async (reason: string, { localization }: FormInputs) => {
      dispatch(
        updateSubscriptionDetails(
          await commonObjectPost(subscriptionCode.urls.revert_conversion, {
            reason,
            kind,
            ...(canSelectLocalization && { localization: localization.value }),
          }),
        ),
      )
      addSuccessMessage('Sukces', 'Opcja została cofnięta!')
    },
  )

  const isConverted = subscriptionCode.options.find(option =>
    kind === 'single_code_kind_convert' ? option.kind.startsWith(kind) : option.kind === kind,
  )
  const isToRevert =
    CONVERTABLE_SUBSCRIPTION_KINDS.includes(subscriptionDetails.type) &&
    !subscriptionCode.is_used &&
    !subscriptionCode.is_splitted &&
    isConverted

  if (!isToRevert || !user.hasPerm(UserPermission.SubscriptionCanRevertCodeConversion)) return

  return (
    <CommonObjectConfirmAction
      askForReason
      className="lh-0"
      isLoading={isLoading}
      handleAccept={revertConversion}
      message={
        <div>
          <span>Czy na pewno chcesz wykonać wybraną akcję? Operacja ta jest nieodwołalna.</span>
          {canSelectLocalization && (
            <FormSelect
              label="Proszę wybrać lokalizację"
              options={AVAILABLE_LOCALIZATION_OPTIONS}
              name="localization"
              formPlainProps={{ colClassName: 'px-0 mt-2' }}
            />
          )}
        </div>
      }
    >
      <i className="uil-history cursor-pointer ml-1" title="Cofnij konwersję" />
    </CommonObjectConfirmAction>
  )
}

const CONVERTABLE_SUBSCRIPTION_KINDS = ['GO_HOLIDAY', 'WITH_BENEFIT', 'WITH_BENEFIT_20', 'DEFAULT']
const AVAILABLE_LOCALIZATION_OPTIONS = [createSelectOption('Morze', 'sea'), createSelectOption('Góry', 'mountains')]

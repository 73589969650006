import * as React from 'react'
import Table from '@components/table/table'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectSubscriptionsAggregation } from '@store/slices/subscription-slice'

export const SubscriptionsTableFooter = () => {
  const aggregation = useSelector(selectSubscriptionsAggregation)

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Łączna kwota:" price={formatPrice(aggregation?.total_price_brutto_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Pozostało do zapłaty:" price={formatPrice(aggregation?.required_payment_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell colSpan={5} className="reservation-list__summary-cell" />
    </Table.Row>
  )
}

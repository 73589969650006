import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { DetailsColRow } from '@hyper/details-col-row'
import { useModal } from '@components/modals/use-modal'
import { BaseModalProps } from '@components/modals/types'
import { useAppData } from '@components/hooks/use-app-data'

interface PromocodePrefixDetailsDialogProps extends BaseModalProps {
  promocodePrefix: PromocodePrefix
}

export const PromocodePrefixDetailsDialogDates: React.FC<{ promocodePrefix: PromocodePrefix }> = ({
  promocodePrefix,
}) => (
  <div className="font-12">
    {promocodePrefix.dates.map(date => (
      <div className="mb-1" key={`${date.date_from}-${date.date_to}`}>
        {`${date.date_from} - ${date.date_to}`}
      </div>
    ))}
  </div>
)

export const PromocodePrefixDetailsDialog: React.FC<PromocodePrefixDetailsDialogProps> = ({
  promocodePrefix,
  toggleIsVisible,
}) => {
  const [handleEdit] = useModal('PromocodePrefixDialog', { promocodePrefix }, { persist: true })

  const handleEditClick = () => {
    handleEdit()
    toggleIsVisible()
  }

  const { resorts, accommodation_types: accommodationTypes } = useAppData()

  const prefixAccommodationTypes = accommodationTypes.filter(accommodationType =>
    promocodePrefix.accommodation_types.includes(accommodationType.id),
  )

  const prefixResorts = resorts.filter(resort => promocodePrefix.resorts.includes(resort.id))

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>{promocodePrefix.name}</ModalHeader>
      <ModalBody>
        <DetailsColRow label="Typ prefiksu" value={promocodePrefix.get_kind_display} className="mb-3 mt-1" />
        <DetailsColRow
          label="Wartość"
          value={
            <span className="text-semi-strong">
              <i className="uil uil-usd-circle font-16 text-muted" /> {promocodePrefix.promo_value_display}
            </span>
          }
        />
        <DetailsColRow
          label="Pozostała liczba kodów"
          value={
            <span className="text-semi-strong">
              {promocodePrefix.codes_amount - promocodePrefix.codes_amount_used} z {promocodePrefix.codes_amount}
            </span>
          }
        />
        <DetailsColRow
          label="Maksymalna liczba dni pobytu"
          value={
            <span className="text-semi-strong">
              {promocodePrefix.max_days !== 0 ? promocodePrefix.max_days : 'Bezograniczeń'}
            </span>
          }
        />
        <div className="hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow label="Opis" value={<span className="text-linebreaksbr">{promocodePrefix.description}</span>} />
        <div className="hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Rodzaj zakwaterowania"
          value={
            <span className="text-linebreaksbr">
              {prefixAccommodationTypes.map(accommodationType => (
                <span key={accommodationType.id} className="text-semi-strong d-block">
                  - {accommodationType.name}
                </span>
              ))}
            </span>
          }
        />
        <div className="hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Ośrodki"
          value={
            <span className="text-linebreaksbr">
              {prefixResorts.map(resort => (
                <span key={resort.id} className="text-semi-strong d-block">
                  - {resort.name}
                </span>
              ))}
            </span>
          }
        />
        <div className="mt-1 hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Daty obowiązywania"
          className="mb-0"
          value={<PromocodePrefixDetailsDialogDates promocodePrefix={promocodePrefix} />}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={handleEditClick}>
          Edytuj
        </Button>
        <Button color="green" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}

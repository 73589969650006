import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { SubscriptionListFilter, SubscriptionListFilterParams } from '@modules/subscription/list/filter'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getSubscriptions } from '@store/actions/subscription-actions'
import { subscriptionStatusList } from '@modules/subscription/models'
import { SubscriptionListTable } from '@modules/subscription/list/table'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { SubscriptionWebSocketHandler } from '@modules/subscription/web-socket-handler'
import { useParams } from 'react-router-dom'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { SubscriptionListFilterButtons } from '@modules/subscription/list/filters-buttons'
import { clearSubscriptions, subscriptionsSelector } from '@store/slices/subscription-slice'
import { SubscriptionDetailsView } from '@modules/subscription/list/details'
import { useTableFilters } from '@components/table/use-table-filters'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: SubscriptionListFilterParams = {
  created_after: undefined,
  accommodation_type: null,
  kind: [],
  option_kinds: [],
  created_before: undefined,
  seller: undefined,
  source_marketing: undefined,
  status: subscriptionStatusList.filter(row => ['initial', 'confirmed'].includes(row.value)),
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  subscription_product_set: null,
}

export const SubscriptionListView: React.FC = () => {
  const { id } = useParams<'id'>()

  const allSubscriptions = useAppSelector(subscriptionsSelector)
  const dispatch = useAppDispatch()

  useSubscriptionAppData()
  useMenuActive(NavigationPath.SubscriptionList)

  const { isLoading, filters, setFilters } = useTableFilters<SubscriptionListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getSubscriptions([filters, 'DEFAULT'])),
  })

  const subscriptions = React.useMemo(
    () =>
      allSubscriptions.filter(row =>
        filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
      ),
    [allSubscriptions, filters.status],
  )

  React.useEffect(
    () => () => {
      dispatch(clearSubscriptions())
    },
    [],
  )

  return (
    <>
      <PageTitleWithSize title="Lista pakietów prywatnych" />
      <Card>
        <SubscriptionWebSocketHandler type="DEFAULT" />
        {id && <SubscriptionDetailsView type="DEFAULT" id={parseInt(id, 10)} />}

        <CardBody>
          <SubscriptionListFilter
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            type="DEFAULT"
          >
            <SubscriptionListFilterButtons />
          </SubscriptionListFilter>
          <SubscriptionListTable
            type="DEFAULT"
            isLoading={isLoading}
            subscriptions={subscriptions}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}

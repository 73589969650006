import * as React from 'react'
import { CustomInput, Label } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import DateRangeInput from '@components/date/date-range-input'
import { ReservationBlockadeFormInputs } from '@modules/reservations/blockade/reservation-blockade-modal'
import { ReservationBlockadeViaDatesSelectedOption } from '@modules/reservations/blockade/via-dates/reservation-blockade-via-dates-selected-option'
import { ReservationBlockadeViaDatesEmptyOption } from '@modules/reservations/blockade/via-dates/reservation-blockade-via-dates-empty-option'
import { AccommodationType } from '@models/booking'

interface Props {
  accommodationTypes: AccommodationType[]
}

export const ReservationBlockadeViaDates = ({ accommodationTypes }: Props): JSX.Element => {
  const methods = useFormContext<ReservationBlockadeFormInputs>()

  const [blockadeDatesKind, dateFrom, dateTo] = useWatch({
    control: methods.control,
    name: ['blockade_dates_kind', 'date_from', 'date_to', 'resort'],
  })

  const disabled = !dateFrom || !dateTo

  return (
    <>
      <FormPlain name="date_from" colClassName="px-0">
        <Label htmlFor="date_range">Zakres dat:</Label>
        <DateRangeInput
          inputClassName="text-dark"
          wrapperClassName="w-75"
          minDate={new Date()}
          startDateName="date_from"
          endDateName="date_to"
        />
      </FormPlain>
      <FormPlain name="blockade_dates_kind" formGroupClassName="my-3" colClassName="px-0">
        <CustomInput
          className="d-inline-block mr-3"
          type="radio"
          value="empty"
          id="empty"
          label="Blokuj wolne"
          disabled={disabled}
          {...extractInnerRef(methods.register('blockade_dates_kind'))}
        />
        <CustomInput
          className="d-inline-block"
          type="radio"
          value="selected"
          id="selected"
          label="Blokuj wybrane"
          disabled={disabled}
          {...extractInnerRef(methods.register('blockade_dates_kind'))}
        />
      </FormPlain>
      {blockadeDatesKind === 'empty' ? (
        <ReservationBlockadeViaDatesEmptyOption accommodationTypes={accommodationTypes} />
      ) : (
        <ReservationBlockadeViaDatesSelectedOption accommodationTypes={accommodationTypes} />
      )}
    </>
  )
}

import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { PackageCode, PackageDetails } from '@models/package'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  packageCode: PackageCode
  packageDetails: PackageDetails
}

export const PackageDetailsCodesRowCode: React.FC<Props> = ({ packageCode, packageDetails }) => {
  const [handleEditCode] = useModal('PackageCodeEditModal', { packageCode, packageDetails })

  return (
    <Table.Cell className="align-middle">
      <div>
        <span onClick={handleEditCode} className="cursor-pointer">
          {packageCode.code}
        </span>
        <CopyToClipboard className="uil-share-alt ml-1" value={packageCode.code} />
      </div>
      <span className="font-11">{toDefaultDateFormat(packageCode.expire_after)}</span>
      {packageDetails.gastro_voucher_amount && (
        <div className="font-11">Voucher gastronomiczny: {formatPriceShort(packageDetails.gastro_voucher_amount)}</div>
      )}
    </Table.Cell>
  )
}

import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { subscriptionProductCreate, subscriptionProductUpdate } from '@api/subscription'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { Form } from '@hyper/forms/form'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { updateSubscriptionProductDetails } from '@store/slices/subscription-slice'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { PackageVipType } from '@modules/promotions/package-vip/models'
import { asDecimal, createSelectOption } from '@helpers/utils'
import * as R from 'ramda'
import { useSubscriptionProductCreateFormOptions } from '@modules/subscription/products/create/use-form-options'
import { SubscriptionProductCreateForm } from '@modules/subscription/products/create/form'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

export interface SubscriptionProductCreateFormInputsCodeFeature {
  gastro_gift: string
  years: string
  package_vip: '' | PackageVipType
}

export interface SubscriptionProductCreateFormInputs {
  status: CustomReactSelectOption | null
  name: string
  code_features: SubscriptionProductCreateFormInputsCodeFeature[]
  local_mountains: number
  local_universal: number
  local_sea: number
  price_brutto: string
  change_client_data_price_brutto: string
  code_localization_convert_price_brutto: string
  zoo_borysew_tickets_single_price_brutto: string
  suntago_tickets_single_price_brutto: string
  accommodation_type: CustomReactSelectOption | null
  client_kind: CustomReactSelectOption | null
  product: number
  kind: CustomReactSelectOption | null
  defaults_days: number | undefined
  kind_version: CustomReactSelectOption | null
  deposit_amount: CustomReactSelectOption | null
  deposit_payment_deadline: CustomReactSelectOption | null
  subscription_product_set: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  subscriptionProduct?: SubscriptionProduct
  type: SubscriptionType
}

const defaultsDaysOptions: CustomReactSelectOption[] = [createSelectOption('6 dób', 6), createSelectOption('5 dób', 5)]

export const SubscriptionProductCreateModal: React.FC<Props> = ({ toggleIsVisible, type, subscriptionProduct }) => {
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { products_defaults } = useSubscriptionAppData()
  const { subscriptionKindOptions, subscriptionKindVersionOptions } = useSubscriptionKinds()
  const subscriptionTypeStrategy = useSubscriptionTypeStrategyProvider(subscriptionProduct?.type || type)

  const {
    depositOptions,
    depositPaymentDeadlineOptions,
    clientKindOptions,
    productSetOptions,
    accommodationTypeOptions,
    subscriptionCodeNameKindOptions,
  } = useSubscriptionProductCreateFormOptions()

  const methods = useForm<SubscriptionProductCreateFormInputs>({
    defaultValues: {
      local_mountains: 0,
      local_universal: 0,
      local_sea: 0,

      code_localization_convert_price_brutto: products_defaults.code_localization_convert_price_brutto,
      change_client_data_price_brutto: products_defaults.change_client_data_price_brutto,
      zoo_borysew_tickets_single_price_brutto: products_defaults.zoo_borysew_tickets_single_price_brutto,
      suntago_tickets_single_price_brutto: products_defaults.suntago_tickets_single_price_brutto,
      ...R.pick(
        [
          'name',
          'prefix',
          'local_sea',
          'local_mountains',
          'local_universal',
          'defaults_days',
          'default_expire_years',
          'price_brutto',
          'code_localization_convert_price_brutto',
          'change_client_data_price_brutto',
          'zoo_borysew_tickets_single_price_brutto',
          'suntago_tickets_single_price_brutto',
          'allow_add_second_client',
        ],
        subscriptionProduct ?? {},
      ),
      deposit_amount: makeDefaultSelectOption(depositOptions, subscriptionProduct?.deposit_amount) ?? depositOptions[2],
      deposit_payment_deadline:
        makeDefaultSelectOption(depositPaymentDeadlineOptions, subscriptionProduct?.deposit_payment_deadline) ??
        depositPaymentDeadlineOptions[0],

      client_kind: makeDefaultSelectOption(clientKindOptions, subscriptionProduct?.client_kind),

      kind: makeDefaultSelectOption(subscriptionKindOptions, subscriptionProduct?.kind),
      kind_version: makeDefaultSelectOption(subscriptionKindVersionOptions, subscriptionProduct?.kind_version),
      subscription_product_set: makeDefaultSelectOption(
        productSetOptions,
        subscriptionProduct?.subscription_product_set,
      ),
      subscription_code_name_kind: makeDefaultSelectOption(
        subscriptionCodeNameKindOptions,
        subscriptionProduct?.subscription_code_name_kind,
      ),
      code_features:
        subscriptionProduct?.code_features.map(row => ({
          ...row,
          package_vip: row.package_vip || '',
          gastro_gift: row.gastro_gift || '',
          years: row.years.join(','),
        })) || [],
      accommodation_type: makeDefaultSelectOption(accommodationTypeOptions, subscriptionProduct?.accommodation_type_id),
      status: makeDefaultSelectOption(packageWholesaleProductsStatusOptions, subscriptionProduct?.status),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: SubscriptionProductCreateFormInputs) => {
    const code_features = payload.code_features.map(row => ({
      ...row,
      package_vip: row.package_vip || null,
      years: row.years ? row.years.split(',') : [],
    }))

    const subscriptionProductDetails = await (!subscriptionProduct
      ? subscriptionProductCreate({ ...payload, code_features }, type)
      : subscriptionProductUpdate(subscriptionProduct.urls.details, { ...payload, code_features }))

    addSuccessMessage(
      'Sukces',
      !subscriptionProduct
        ? `Produkt "${subscriptionProductDetails.name}" został dodany`
        : `Produkt "${subscriptionProductDetails.name}" został zmieniony`,
    )
    toggleIsVisible()
    dispatch(updateSubscriptionProductDetails(subscriptionProductDetails))
  }, methods.setError)

  const accommodationType = useWatch({ control: methods.control, name: 'accommodation_type.value' })

  React.useEffect(() => {
    if (subscriptionProduct) return

    const houseDefaultDeposit = 899
    const apartmentDefaultDeposit = 750

    const deposit = ACCOMMODATION_TYPES.HOUSES.includes(accommodationType)
      ? houseDefaultDeposit
      : apartmentDefaultDeposit

    const depositOption = depositOptions.find(option => asDecimal(option.value).equals(deposit))
    if (depositOption) methods.setValue('deposit_amount', depositOption)
  }, [accommodationType])

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {!subscriptionProduct ? (
          'Dodaj produkt'
        ) : (
          <>
            Edytuj produkt <span className="text-semi-strong">{subscriptionProduct.name}</span>
          </>
        )}
      </ModalHeader>
      <ModalBody className="pb-0">
        <SubscriptionProductCreateForm
          subscriptionTypeStrategy={subscriptionTypeStrategy}
          defaultsDaysOptions={defaultsDaysOptions}
          subscriptionType={type}
        />
      </ModalBody>
      <ModalFooter>
        {subscriptionProduct && user.is_superuser && (
          <a href={subscriptionProduct?.urls.admin} className="mr-auto">
            <IconWithText icon="uil-pen" text="Edytuj" wrapperClassNames="text-strong text-secondary cursor-pointer" />
          </a>
        )}
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { SubscriptionDetails, SubscriptionOption } from '@modules/subscription/models'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { setSubscriptionDetails } from '@store/slices/subscription-slice'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  option: SubscriptionOption
  index: number
}

export const SubscriptionDetailsInformationOptionsRow = ({ option, index }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(setSubscriptionDetails(await commonObjectDelete<SubscriptionDetails>(option.urls.details)))
    addSuccessNotification('Opcja została skasowana!')
  })

  return (
    <tr>
      <td width={10}>{index + 1}.</td>
      <td>{option.kind_display}</td>
      <td>
        {option.is_paid ? (
          <BadgeWithIcon
            icon="uil-check-circle"
            title={option.amount_left ? 'Opłacone' : 'Wykorzystane'}
            variant={option.amount_left ? 'success' : 'warning'}
          />
        ) : (
          <BadgeWithIcon icon="uil-ban" title="Nieopłacone" variant="danger" />
        )}
      </td>
      <td>
        {option.amount_left}/{option.amount}
      </td>
      <td>{formatPriceShort(option.price_brutto)}</td>
      <td>
        {option.can_delete && (
          <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
            <i className="uil-trash cursor-pointer" title="Skasuj opcje" />
          </CommonObjectConfirmAction>
        )}
      </td>
    </tr>
  )
}

import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { ReceptionBooking } from '@models/reception'
import { formatPhoneNumber } from '@helpers/utils'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBooking
  emailClassName?: string
}

export const ReservationGuestContactData = ({ booking, emailClassName }: Props): React.ReactNode => (
  <>
    <IconWithText
      icon="uil-envelope-alt text-secondary font-16 lh-0"
      text={
        <>
          {booking.email}
          <CopyToClipboard className="ml-1" value={booking.email} />
        </>
      }
      textClass="font-weight-bold"
      flexClassName="d-block"
      wrapperClassNames={classNames(emailClassName, 'mb-1')}
    />

    <IconWithText
      icon="uil-comment-alt-message text-secondary font-16 lh-0"
      text={booking.phone ? formatPhoneNumber(booking.phone) : 'brak numeru telefonu'}
      textClass="font-weight-bold"
    />
  </>
)

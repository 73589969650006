import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getPromocodePrefixes } from '@store/actions/promocode-actions'
import { PromocodePrefixesTable } from '@modules/promocode/promocode-prefixes/promocode-prefixes-table'
import { useDocumentTitle } from '@helpers/utils'
import { PromocodePrefixCreate } from '@modules/promocode/promocode-prefixes/promocode-prefix-create'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'
import { PromocodePrefixesFilters } from '@modules/promocode/promocode-prefixes/promocode-prefixes-filters'
import { TableFilters } from '@components/table/table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { promocodePrefixesSelector } from '@store/slices/promocodes-slice'
import { useTableFilters } from '@components/table/use-table-filters'

const pageTitle = 'Lista prefiksów'

const defaultFilters: TableFilters = {
  ordering: '-prefix',
  page: 1,
  page_size: 30,
  search: '',
}
export const PromoCodePrefixesView: React.FC = () => {
  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.PromoCodePromoCodes)

  const action = React.useCallback(async filters => await dispatch(getPromocodePrefixes(filters)), [])

  const { isLoading, filters, setFilters } = useTableFilters<TableFilters>({
    defaultFilters,
    action,
  })

  const prefixes = useAppSelector(promocodePrefixesSelector)

  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: 'Lista kodów promocyjnych i voucherów',
            path: NavigationPath.PromoCodePromoCodes,
            active: false,
          },
          {
            label: pageTitle,
            path: '',
            active: true,
          },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <Row>
            <Col sm={6}>
              <Link to={NavigationPath.PromoCodePromoCodes} className="btn btn-light">
                Wróc do listy
              </Link>
            </Col>
            <Col sm={6} className="text-right">
              <PromocodePrefixCreate />
            </Col>
          </Row>

          <div data-testid="qa-prefixes-table">
            <PromocodePrefixesFilters setFilters={setFilters} filters={filters} />
            <PromocodePrefixesTable
              isLoading={isLoading}
              prefixes={prefixes}
              setFilters={setFilters}
              filters={filters}
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { createApiTag } from '@api/query-helper'
import { RentAppData } from '@models/rent'

const RENT_TAG = 'rent'
export const RENT_TAG_IDS = {
  APP_DATA: 'APP_DATA',
}

export const rentApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'rentApi',
  tagTypes: [RENT_TAG],
  endpoints: builder => ({
    rentAppData: builder.query<RentAppData, void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.rent.app_data,
      })),
      providesTags: createApiTag(RENT_TAG, RENT_TAG_IDS.APP_DATA),
    }),
  }),
})

export const { useRentAppDataQuery } = rentApi

import * as React from 'react'
import { SubscriptionCode } from '@modules/subscription/models'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'

interface Props {
  subscriptionCode: SubscriptionCode
  canRenew: boolean
}

export const SubscriptionDetailsCodesRowRenewAction: React.FC<Props> = ({ subscriptionCode, canRenew }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading: isRenewLoading, action: handleRenew } = useApiRequest(async () => {
    dispatch(updateSubscriptionDetails(await commonObjectPost(subscriptionCode.urls.renew)))
    addSuccessMessage('Sukces', `Kod ${subscriptionCode.code} został odnowiony!`)
  })

  if (!canRenew || !subscriptionCode.is_used || subscriptionCode.is_splitted || subscriptionCode.kind === 'inactive')
    return null

  return (
    <SaveButton
      type="button"
      isSaving={isRenewLoading}
      onClick={handleRenew}
      className="btn btn-xs btn-danger mr-1"
      label="Odnów"
      labelSaving="Odnawianie"
    />
  )
}

import { GastroGenericProductTag, GastroProductKind } from '@models/products'
import { DashboardUrlsState, UserPermission } from '@models/dashboard'
import { asDecimal } from '@helpers/utils'
import { GastroCardKind } from '@models/promotions'

export const getGastroProductApiUrl = (kind: GastroProductKind, urls: DashboardUrlsState) =>
  urls.promotions.gastro_generic.replace('replace-me', kind)

export const getGastroProductPercentageBonus = <T extends { promotion_multiplier: string }>(gastroCard: T) =>
  `${asDecimal(gastroCard.promotion_multiplier).minus(1).mul(100).toString()}%`

export const getGastroProductApiInfoUrl = (kind: GastroProductKind, urls: DashboardUrlsState) =>
  `${getGastroProductApiUrl(kind, urls)}info/`

export const getGastroGenericProductTag = (tagKey: string, appDataTags: GastroGenericProductTag[]) =>
  appDataTags.find((appDataTag: GastroGenericProductTag) => appDataTag.id === tagKey)

interface GastroGenericPermission {
  sellerChange: { initial: UserPermission; any: UserPermission }
}

export const GastroGenericPermissions: Partial<Record<GastroCardKind, GastroGenericPermission>> = {
  gastro_card_boost: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCardBoostCanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCardBoostCanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2024: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2024CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2024CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2025: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2025CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2025CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2026: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2026CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2026CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2026_october: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2026OctoberCanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2026OctoberCanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2026_november: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2026NovemberCanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2026NovemberCanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_2027: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard2027CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard2027CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_dynamic: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCardDynamicCanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCardDynamicCanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_black_week_2024: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCard20CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCard20CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_christmas_2024: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCardChristmas2024CanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCardChristmas2024CanChangeSellerInAnyVoucherStatus,
    },
  },
  gastro_card_promo_february_2025: {
    sellerChange: {
      initial: UserPermission.PromotionsGastroCardFebruary2025PromoCanChangeSellerInInitialVoucherStatus,
      any: UserPermission.PromotionsGastroCardFebruary2025PromoCanChangeSellerInAnyVoucherStatus,
    },
  },
}

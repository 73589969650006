import * as React from 'react'
import Table from '@components/table/table'
import { PackageDetailsCodesRowActions } from '@modules/package/details/codes/actions'
import { PackageDetailsCodesRowCode } from '@modules/package/details/codes/code'
import { PackageCode, PackageDetails } from '@models/package'
import { YesNoBadge } from '@components/badges/yes-no'
import { PackageDetailsCodesRowName } from '@modules/package/details/codes/row-name'

interface Props {
  packageCode: PackageCode
  index: number
  selectableCell: (packageCode: PackageCode) => React.ReactElement
  canRemoveCodes: boolean
  packageDetails: PackageDetails
}

export const PackageDetailsCodesRow: React.FC<Props> = ({
  packageCode,
  index,
  selectableCell,
  canRemoveCodes,
  packageDetails,
}) => (
  <Table.Row>
    {canRemoveCodes && selectableCell(packageCode)}
    <Table.Cell className="align-middle" style={{ width: 10 }}>
      <a href={packageCode.urls.admin} target="_blank">
        {index}.
      </a>
    </Table.Cell>
    <PackageDetailsCodesRowCode packageCode={packageCode} packageDetails={packageDetails} />
    <Table.Cell className="align-middle">{packageCode.kind_display}</Table.Cell>
    <PackageDetailsCodesRowName packageCode={packageCode} />
    <Table.Cell className="align-middle">{packageCode.description}</Table.Cell>
    <Table.Cell className="align-middle">{packageCode.towel_amount}</Table.Cell>
    <Table.Cell className="align-middle">
      <YesNoBadge value={packageCode.is_active} />
    </Table.Cell>
    <Table.Cell className="align-middle">
      <YesNoBadge value={packageCode.with_cleaning_option || packageCode.with_free_cleaning} />
    </Table.Cell>
    <Table.Cell className="align-middle">
      {packageCode.is_splitted ? (
        <>
          tak ({packageCode.splitted_used} z {packageCode.splitted_available})
        </>
      ) : (
        'nie'
      )}
    </Table.Cell>
    <Table.Cell className="align-middle">
      <YesNoBadge value={packageCode.is_used} />
    </Table.Cell>
    <PackageDetailsCodesRowActions packageCode={packageCode} packageType={packageDetails.package_type} />
  </Table.Row>
)

import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { CheckStats } from '@components/topbar/check-stats'
import { ProfileDropdown } from '@components/topbar/profile-dropdown'
import { SmallSearchbar } from '@components/topbar/small-searchbar'
import { NotificationBar } from '@components/topbar/notification-bar'
import { Searchbar } from '@components/topbar/searchbar'
import classNames from 'classnames'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { setDashboardSidebar } from '@store/slices/app-slice'
import { useAppData } from '@components/hooks/use-app-data'

interface TopbarProps {
  search: boolean
  stats: boolean
  showMenu: boolean
  showNotification: boolean
  children?: React.ReactNode
}

export const Topbar: React.FC<TopbarProps> = ({ showNotification, search, stats, showMenu, children }) => {
  const user = useAuthenticatedUser()
  const checkStats = useAppSelector((state: RootState) => state.appState.appData.check_stats)
  const { urls } = useAppData()
  const dispatch = useAppDispatch()
  const isSidebar = useAppSelector((state: RootState) => state.appState.isSidebar)
  const isMobileView = useAppSelector((state: RootState) => state.appState.isMobileView)
  const toggleSidebar = React.useCallback(() => dispatch(setDashboardSidebar(!isSidebar)), [isSidebar])

  if (!user || !checkStats || !urls) {
    return null
  }

  return (
    <div className={classNames('navbar-custom', { 'is-mobile-view': isMobileView })} id="navbar">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        {search && (
          <li className="dropdown notification-list d-lg-none">
            <SmallSearchbar quickSearchUrl={urls.dashboard.quick_search_url} />
          </li>
        )}
        {showNotification && (
          <li className="dropdown notification-list">
            <NotificationBar />
          </li>
        )}
        <li className="dropdown notification-list">
          <ProfileDropdown urls={urls} />
        </li>
      </ul>
      {showMenu && (
        <button
          className={classNames('button-menu-mobile open-left', { 'disable-btn': !isMobileView })}
          onClick={toggleSidebar}
          role="sidebar-button"
        >
          <i className="uil uil-bars" />
        </button>
      )}
      {children}
      <div className="d-none d-lg-flex align-items-center">
        {search && <Searchbar />}

        {stats && <CheckStats checkStats={checkStats} />}
      </div>
    </div>
  )
}

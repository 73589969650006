import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionRowApartment } from '@modules/reception/common/reception-row-apartment'
import { ReceptionRowName } from '@modules/reception/common/reception-row-name'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { ReceptionRowRestToPay } from '@modules/reception/common/reception-row-rest-to-pay'
import { ReceptionRowNotes } from '@modules/reception/common/reception-row-notes'
import { ReceptionCompleteCheckInRowGuests } from '@modules/reception/complete-checkin/row/reception-complete-check-in-row-guests'
import { ReceptionCompleteCheckInRowParking } from '@modules/reception/complete-checkin/row/reception-complete-check-in-row-parking'
import { ReceptionToCheckInRowImprovements } from '@modules/reception/to-checkin/row/reception-to-check-in-row-improvements'
import { ReceptionCompleteCheckInRowActions } from '@modules/reception/complete-checkin/row/reception-complete-check-in-row-actions'
import { ReceptionBookingExtensionButton } from '@modules/reception/common/reception-booking-extension-button'

interface ReceptionCompleteCheckInRowProps {
  booking: ReceptionBooking
  hasBands: boolean
}

export const ReceptionCompleteCheckInRow: React.FC<ReceptionCompleteCheckInRowProps> = ({ booking, hasBands }) => (
  <tr className="reception__bookings_table__row" role="booking-complete-check-in">
    <td className="reception__bookings_table__column reception__bookings_table__column__first">
      <ReceptionRowApartment booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionRowName booking={booking} />
    </td>
    <td className="p-0 valign-middle reception__bookings_table__column">
      <ReceptionCompleteCheckInRowGuests booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionRowDates booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionToCheckInRowImprovements booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionCompleteCheckInRowParking booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionRowRestToPay booking={booking} />
    </td>
    <td className="valign-middle reception__bookings_table__column">
      <ReceptionRowNotes booking={booking} />
    </td>
    <td className="valign-middle text-center reception__bookings_table__column d-flex justify-content-end">
      <ReceptionBookingExtensionButton booking={booking} />
      {hasBands && <ReceptionCompleteCheckInRowActions booking={booking} />}
    </td>
  </tr>
)

import { PackageDetails, PackageType } from '@models/package'
import { commonObjectPost } from '@store/actions/generic-actions'
import store, { getState } from '@store/index'
import { formatDate } from '@helpers/date-helper'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { ParamsToCalculate } from '@modules/package/create/steps/params/params'
import { extractSelectOptionsValues } from '@helpers/utils'
import { createFormData, extractUndefinedPropertiesFromObject } from '@helpers/forms'

export const packageCreate = async (
  payload: PackageCreateFormInputs,
  packageType: PackageType,
): Promise<PackageDetails> => {
  const url = store.getState().appState.appData.urls.package.packages

  return await commonObjectPost<PackageDetails>(
    `${url}?package_type=${packageType}`,
    createFormData(
      extractUndefinedPropertiesFromObject({
        ...(extractSelectOptionsValues(payload) as object),
        package_type: packageType,
        expire_after: formatDate(payload.expire_after),
      }),
    ),
  )
}

export const calculatePrice = async (payload: ParamsToCalculate): Promise<number> => {
  const url = getState().appState.appData.urls.package.calculate
  return await commonObjectPost<number>(url, {
    ...extractSelectOptionsValues(payload),
    package_type: payload.package_type.toUpperCase(),
  })
}

import * as React from 'react'
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { Agreement, AnnexTypeId } from '@modules/hr/agreement/models'
import { Form } from '@hyper/forms/form'
import { useForm, useWatch } from 'react-hook-form'
import { AgreementHourlyRateInput } from '@modules/hr/agreement/create/steps/job-data/hourly-rate-input'
import { asDecimal, createSelectOption, extractSelectOptionsValues, getById } from '@helpers/utils'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { addMonths, min, startOfMonth, startOfToday } from 'date-fns'
import { useCreateAgreementAnnexMutation } from '@api/agreements'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { getMaximumAgreementAnnexDate } from '@modules/hr/agreement/annexes/helpers'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface FormInputs {
  hourly_rate: string
  job_position: CustomReactSelectOption
  effective_date: Date
}

interface Props extends BaseModalProps {
  agreement: Agreement
}

export const AgreementHourlyRateAnnexModal = ({ toggleIsVisible, agreement }: Props): JSX.Element => {
  const { refresh } = useAgreementDetails({ agreement, withClear: false, immediateFetch: false })

  const { job_positions } = useHrAppData()
  const { addSuccessMessage } = useNotificationHook()

  const defaultPosition = getById(job_positions, agreement.job_position)

  const methods = useForm<FormInputs>({
    defaultValues: {
      hourly_rate: agreement.hourly_rate,
      job_position: createSelectOption(defaultPosition?.name, defaultPosition?.id),
      effective_date: min([getMaximumAgreementAnnexDate(agreement), startOfMonth(addMonths(startOfToday(), 1))]),
    },
  })

  const [createAgreementAnnex, { isLoading, error, isError }] = useCreateAgreementAnnexMutation()

  useHandleRtkQueryError(error, isError, methods.setError)

  const [hourlyRate, selectedPositionId] = useWatch({
    control: methods.control,
    name: ['hourly_rate', 'job_position.value'],
  })

  const selectedPosition = getById(job_positions, selectedPositionId)

  const earningsChanged = !asDecimal(hourlyRate).eq(methods.formState.defaultValues?.hourly_rate ?? 0)
  const positionChanged = selectedPositionId !== agreement.job_position

  const onSubmit = async (payload: FormInputs) => {
    let annexType: AnnexTypeId | null = null

    if (earningsChanged) annexType = 'earning_change' as const
    if (positionChanged) annexType = 'task_change' as const
    if (earningsChanged && positionChanged) annexType = 'task_and_earning_change' as const

    if (!annexType) return

    await createAgreementAnnex({
      url: agreement.urls.create_annex,
      payload: {
        ...(extractSelectOptionsValues(payload) as object),
        document_type: annexType,
        agreement: agreement.id,
      },
    }).unwrap()

    refresh()
    addSuccessMessage('Sukces', 'Aneks został dodany.')

    toggleIsVisible()
  }

  useDidUpdateEffect(() => {
    methods.setValue('hourly_rate', selectedPosition?.earnings_from ?? '')
  }, [selectedPositionId])

  const jobOptions = job_positions.reduce((options, job) => {
    if (job.company === agreement.company) return [...options, createSelectOption(job.name, job.id)]
    return options
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Aneks do umowy nr. {agreement.number}</ModalHeader>
      <ModalBody className="rounded">
        <FormPlain colClassName="p-0" name="effective_date">
          <Label>Data wejścia zmiany w życie</Label>
          <DatePickerInput
            name="effective_date"
            minDate={startOfToday()}
            maxDate={getMaximumAgreementAnnexDate(agreement)}
          />
        </FormPlain>
        <FormSelect
          label="Stanowisko"
          name="job_position"
          options={jobOptions}
          formPlainProps={{ colClassName: 'px-0' }}
        />
        {selectedPosition && (
          <div className="mb-3">
            <strong>Zakres obowiązków:</strong>
            <div className="border rounded bg-light-grey p-2 mt-1 font-12 text-pre-wrap">
              {selectedPosition?.responsibilities}
            </div>
          </div>
        )}
        <AgreementHourlyRateInput position={selectedPosition} colClassName="col-12 px-0" />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          isSaving={isLoading}
          className="btn btn-green"
          label="Zapisz"
          disabled={!earningsChanged && !positionChanged}
        />
      </ModalFooter>
    </Form>
  )
}

import { BaseHistory, ContentNote, DepositPaymentDeadline, Document } from '@models/dashboard'
import { Payment } from '@models/payments'
import { EmailNotification } from '@models/notifications'
import { BaseUrls } from '@models/base'
import { SubscriptionKind } from '@modules/subscription/models'

interface PackageUrls {
  admin: string
  details: string
}

export type PackageStatus = 'initial' | 'confirmed' | 'completed' | 'cancelled'

export interface PackageEntranceTicketUrls {
  print: string
}

export type PackageEntranceTicketStatus = 'active' | 'used' | 'canceled'

export type PackageEntranceTicketKind = 'zoo_borysew' | 'suntago'

export interface PackageEntranceTicket {
  code: string
  expire_after: string
  id: number
  related_code: string
  status: PackageEntranceTicketStatus
  status_display: string
  urls: PackageEntranceTicketUrls
  used_at: null | string
  kind: PackageEntranceTicketKind
}

export interface PackageAggregation {
  apartments_mountains_sum: string
  apartments_sea_sum: string
  houses_mountains_sum: string
  houses_sea_sum: string
  price_brutto_sum: string
}

export interface Package {
  kind_version_display: string
  kind_version: number | null
  kind_display: string
  kind: SubscriptionKind
  apartments_mountains: number
  apartments_sea: number
  apartments_universal: number
  confirmed_at: string
  created: string
  created_by: string
  email: string
  houses_mountains: number
  houses_sea: number
  houses_universal: number
  id: number
  invoice_company: string
  invoice_nip: string
  invoice_number: string
  name: string
  number: string
  package_type: 'HPR' | 'HPI'
  payment_date: string
  phone: string
  price_brutto: string
  price_netto: string
  entrance_tickets: PackageEntranceTicket[]
  required_payment: string
  seller: string
  seller_id: number
  status: PackageStatus
  status_display: string
  urls: PackageUrls
}

interface PackageCodeUrls {
  admin: string
  details: string
  convert: string
  renew: string
}

export type LocalizationKind = 'mountains' | 'sea' | 'universal'

export interface PackageCode {
  code: string
  description: string
  expire_after: string
  id: number
  is_active: boolean
  is_splitted: boolean
  is_used: boolean
  kind: 'apartment' | 'house' | 'both'
  kind_display: string
  localization_kind: LocalizationKind
  localization_kind_display: string
  name: string
  splitted_available: number
  splitted_used: number
  checked_in: boolean
  towel_amount: number
  type: 'inactive' | 'default'
  urls: PackageCodeUrls
  with_cleaning_option: boolean
  with_free_cleaning: boolean
}

interface PackageBooking {
  created_at: string
  id: number
  name: string
  reservation_number: string
  resort_id: number
  status: string
  status_display: string
  urls: BaseUrls
}

interface PackageDetailsUrls extends PackageUrls {
  remove_codes: string
  documents: string
  invoice_create: string
  notifications: string
  params: string
  payment_create: string
  entrance_tickets_download: string
}

interface PackageInvoiceBookingUrls {
  resend: string
}
export interface PackageInvoiceBooking {
  last_synchronization: string
  reservation_number: number
  id: number
  urls: PackageInvoiceBookingUrls
}

export interface PackageDetails extends Package {
  allow_split_code: boolean
  apartment_single_price: string
  bookings: PackageBooking[]
  codes: PackageCode[]
  custom_invoice_position: string
  default_code_activate: boolean
  defaults_days: number
  deposit_amount: number
  deposit_payment_deadline: DepositPaymentDeadline
  deposit_payment_deadline_display: string
  documents: Document[]
  email_notifications: string
  expire_after: string
  gastro_voucher_amount: null | string
  has_invoice: boolean
  history: BaseHistory[]
  house_single_price: number
  instruction: string
  invoice_booking?: PackageInvoiceBooking
  invoice_city: string
  invoice_company: string
  invoice_custom_number: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  is_active: boolean
  is_paid: boolean
  notes: ContentNote[]
  notifications: EmailNotification[]
  package_source_type: 'DEFAULT' | 'HPI'
  payments: Payment[]
  prefix: string
  price_brutto: string
  rent_towel_amount: number
  service_charge_included: boolean
  special_price_brutto: string
  suntago_tickets_single_price_brutto: string
  urls: PackageDetailsUrls
  with_cleaning_option: boolean
  with_first_car_parking: boolean
  with_second_car_parking: boolean
  with_free_first_car_parking: boolean
  with_free_second_car_parking: boolean
  with_free_media_option: boolean
  with_free_cleaning: boolean
  with_free_towels: boolean
  with_suntago_tickets: boolean
  with_zoo_borysew_tickets: boolean
  zoo_borysew_tickets_single_price_brutto: string
}

export const packageStatusList: { value: PackageStatus; label: string }[] = [
  {
    value: 'cancelled',
    label: 'Anulowane',
  },
  {
    value: 'confirmed',
    label: 'Potwierdzone',
  },
  {
    value: 'completed',
    label: 'Zakończone',
  },
  {
    value: 'initial',
    label: 'Wstępne',
  },
]

export enum PackageType {
  HPI = 'hpi',
  HPR = 'hpr',
}

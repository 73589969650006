import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { Button, Label, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput, FormPlain } from '@hyper/forms'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { parseISODate, toDefaultDateTimeFormat } from '@helpers/date-helper'
import DatePickerInput from '@components/date/date-picker-input'
import { addYears, endOfMonth, isSameDay } from 'date-fns'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  auto_cancel_date: Date | null
  reason: string
}

export const ReservationDisableAutoCancelModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      auto_cancel_date: booking.auto_cancel_after_custom
        ? parseISODate(booking.auto_cancel_after_custom)
        : booking.auto_cancel_after
          ? parseISODate(booking.auto_cancel_after)
          : null,
    },
  })

  const changeDate = async (formData: FormInputs) => {
    if (!formData.auto_cancel_date) return

    const initialDateChanged = !isSameDay(parseISODate(booking.auto_cancel_after) as Date, formData.auto_cancel_date)

    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
          reason: formData.reason,
          auto_cancel_after_custom: initialDateChanged ? formData.auto_cancel_date : null,
        }),
      ),
    )

    addSuccessMessage()
    toggleIsVisible()
  }

  const { isLoading: isSubmitting, action: onSubmit } = useFormRequest(changeDate, methods.setError, {
    showGlobalError: true,
  })

  const bookingTooltipId = `booking-${booking.id}`

  const handleInitialDateRevert = () => {
    methods.setValue('auto_cancel_date', booking.auto_cancel_after ? parseISODate(booking.auto_cancel_after) : null)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Zmiana ustawień auto-anulowania</ModalHeader>
        <ModalBody>
          <Row>
            <FormPlain name="auto_cancel_date">
              <Label className="d-flex justify-content-between align-items-center">
                <div>Data auto-anulowania rezerwacji</div>
                {!!booking.auto_cancel_after_custom && (
                  <div>
                    <Button
                      className="shadow-none bg-transparent border-0 text-default p-0"
                      onClick={handleInitialDateRevert}
                      type="button"
                    >
                      <span className="pr-1">Przywróć domyślną datę</span>
                      <i className="uil-info-circle" id={bookingTooltipId} />
                    </Button>
                    <UncontrolledTooltip placement="top" target={bookingTooltipId}>
                      <span className="font-11">
                        Domyślna data auto-anulowania rezerwacji to{' '}
                        <strong>{toDefaultDateTimeFormat(booking.auto_cancel_after)}</strong>
                      </span>
                    </UncontrolledTooltip>
                  </div>
                )}
              </Label>
              <DatePickerInput
                minDate={new Date()}
                maxDate={endOfMonth(addYears(new Date(), 5))}
                control={methods.control}
                name="auto_cancel_date"
                wrapperClassName="flex-grow-1"
              />
            </FormPlain>
            <FormInput label="Powód zmiany" name="reason" type="textarea" />
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button type="button" onClick={toggleIsVisible} className="btn btn-light">
            Anuluj
          </button>
          <SaveButton isSaving={isSubmitting} className="btn btn-green" label="Zapisz" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}

import { BaseHistory, ContentNote, DepositPaymentDeadline } from '@models/dashboard'
import { EmailNotification } from '@models/notifications'
import { Payment } from '@models/payments'
import { LocalizationKind, PackageEntranceTicket } from '@models/package'
import { InvoiceType } from '@models/promotions'
import { BaseUrls } from '@models/base'
import { ClientUser } from '@models/clients'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { BasePackageWholesale } from '@modules/package-wholesale/models'
import { PackageVipType } from '@modules/promotions/package-vip/models'
import { AccommodationTypeKind } from '@models/booking'

export type SubscriptionStatus =
  | 'cancelled'
  | 'completed'
  | 'confirmed'
  | 'initial_cancelled'
  | 'initial'
  | 'unfinished_canceled'
  | 'unfinished'

export type SubscriptionKind = 'brown' | 'gray' | 'silver' | 'gold' | 'platinum'

export const subscriptionKindOptions: CustomReactSelectOption[] = [
  createSelectOption('Złoty', 'gold'),
  createSelectOption('Srebrny', 'silver'),
  createSelectOption('Brązowy', 'brown'),
  createSelectOption('Szary', 'gray'),
  createSelectOption('Platynowy', 'platinum'),
]

interface SubscriptionProductUrls extends BaseUrls {
  admin: string
}

export type SubscriptionCodeNameKind = 'name' | 'nameless'

export interface SubscriptionProduct {
  allow_add_second_client: boolean
  subscription_code_name_kind: SubscriptionCodeNameKind
  subscription_code_name_kind_display: string
  deposit_payment_deadline: DepositPaymentDeadline
  deposit_payment_deadline_display: string
  defaults_days: number
  status: 'unavailable' | 'available'
  status_display: string
  subscription_disposable_client_data_change_price: string
  subscription_disposable_booking_date_change_price: string
  zoo_borysew_tickets_single_price_brutto: string
  suntago_tickets_single_price_brutto: string
  accommodation_type_id: number
  kind_version: number | null
  kind_version_display: string
  change_client_data_price_brutto: string
  client_kind: string
  client_kind_display: string
  code_localization_convert_price_brutto: string
  default_expire_years: number
  deposit_amount: string
  id: number
  kind: SubscriptionKind
  kind_display: string
  local_mountains: number
  local_universal: number
  local_sea: number
  name: string
  prefix: string
  price_brutto: string
  type: SubscriptionType
  urls: SubscriptionProductUrls
  zoo_borysew_tickets_price_brutto: string
  suntago_tickets_price_brutto: string
  expiration_extension_single_price_brutto: string
  code_features: SubscriptionProductCodeFeature[]
  with_cleaning_option: boolean
  with_parking: boolean
  with_towel: boolean
  with_grill: boolean
  with_client_change_data_option: boolean
  subscription_product_set: number | null
}

export interface SubscriptionProductCodeFeature {
  id: number
  years: string[]
  gastro_gift: null | string
  package_vip: null | PackageVipType
}

interface SubscriptionDetailsUrls extends BaseUrls {
  admin: string
  codes_convert: string
  entrance_tickets_download: string
  global_codes_merge: string
  notifications: string
  payments: string
  proforma_invoice: string
  sale: string
  subscription_expiration_extension: string
  subscription_client_data_change: string
  subscription_single_change_localization: string
  subscription_change_localization: string
  subscription_upgrade_to_purple: string
  subscription_single_client_data_change: string
  subscription_single_code_convert: string
  subscription_single_date_change: string
  subscription_entrance_tickets: string
  tourist_vouchers: string
}

export interface SubscriptionBookingUrls {
  details: string
  admin: string
}

export interface SubscriptionBookingCode {
  code: string
  id: number
}

export interface SubscriptionBooking {
  created_at: string
  codes: SubscriptionBookingCode[]
  id: number
  last_synchronization: string
  name: string
  promocode: string
  reservation_number: string
  resort_id: number
  status: string
  status_display: string
  subscription_allow_change_client_date: boolean
  subscription_allow_change_client_data: boolean
  total_payment: string
  urls: SubscriptionBookingUrls
}

export type SubscriptionType =
  | 'DEFAULT'
  | 'WHOLESALE'
  | 'WITH_BENEFIT'
  | 'GO_HOLIDAY'
  | 'WITH_BENEFIT_20'
  | 'CAFETERIA'
  | 'BOOKING_IMPROVEMENT'
  | 'PRODUCT_PACKAGE_VOUCHER'
  | 'PRODUCT_PACKAGE'

export interface SubscriptionAggregation {
  required_payment_sum: string
  total_price_brutto_sum: string
  total_price_brutto_without_used_booking_sum: string
}

export interface Subscription {
  accommodation_type_id: number
  created: string
  created_by: string
  created_by_id: number | null
  email: string
  entrance_tickets: PackageEntranceTicket[]
  id: number
  kind: SubscriptionKind
  kind_display: string
  kind_version: number | null
  kind_version_display: string
  client_kind_display: string
  local_mountains: number
  local_universal: number
  local_sea: number
  name: string
  number: string
  options: SubscriptionOption[]
  product_id: number
  product: SubscriptionProduct | null
  read_only: boolean
  required_payment: string
  seller: string
  seller_id: number | null
  source_marketing_id: number
  status: SubscriptionStatus
  subscription_code_name_kind: 'nameless' | 'name'
  status_display: string
  total_price_brutto: number
  total_price_brutto_without_used_booking: number
  type: SubscriptionType
  type_display: string
  type_value: number
  urls: BaseUrls
  used_codes: number
}

export interface SubscriptionCodeUrls {
  admin: string
  convert: string
  details: string
  expiration_extension: string | null
  revert_conversion: string
  renew: string
  activate: string
}

export interface SubscriptionCodeFeature {
  id: number
  years: string[]
  gastro_gift: null | string
  package_vip: null | string
  code_with_benefit: boolean
}

export interface SubscriptionCode {
  options: SubscriptionOption[]
  subscription_kind_display: string | null
  name: string
  id: number
  code: string
  expire_after: string
  is_splitted: boolean
  splitted_used: number
  splitted_available: number
  is_used: boolean
  localization_kind: LocalizationKind
  localization_kind_display: string
  kind: 'default' | 'inactive' | 'localization' | 'used_in_package_vip'
  kind_display: string
  urls: SubscriptionCodeUrls
  features: SubscriptionCodeFeature[]
}

interface SubscriptionPaymentMoveHistoryParams {
  type: string
  amount: string
  source: string
  new_reservation: string
  old_reservation: string
}

export interface SubscriptionPaymentMoveHistory {
  id: number
  message: string
  params: SubscriptionPaymentMoveHistoryParams
  old_reservation: string
  new_reservation: string
}

export interface SubscriptionTouristVoucher {
  id: number
  code: string
  name: string
  confirmed_at: string
  amount: string
  created_by: string
  is_confirmed: boolean
  urls: BaseUrls
}

export interface SubscriptionOptionPriceList {
  id: number
  option_kind: SubscriptionOptionKind
  price_brutto: string
  accommodation_type_kind: AccommodationTypeKind
  kind: SubscriptionKind
}

export type SubscriptionOptionKind =
  | 'client_data'
  | 'localization'
  | 'localization_unlimited'
  | 'single_client_date'
  | 'single_client_data'
  | 'zoo_borysew'
  | 'suntago'
  | 'expiration_extension'
  | 'single_code_kind_convert_to_gold'
  | 'single_code_kind_convert_to_silver'
  | 'single_code_kind_convert_to_platinum'
  | 'upgrade_to_purple'

export type SubscriptionOptionKindPrice = Partial<{
  [key in SubscriptionOptionKind]: string
}>

export interface SubscriptionOption {
  id: number
  kind: SubscriptionOptionKind
  kind_display: string
  amount: number
  amount_left: number
  price_brutto: string
  rest_to_pay: string
  can_delete: boolean
  is_paid: boolean
  urls: BaseUrls
}

export interface SubscriptionDetails extends Subscription {
  auto_cancel_after_date: string | null
  bookings: SubscriptionBooking[]
  city: string
  client: ClientUser | null
  client_id: number
  client_kind: string
  client_kind_display: string
  clients: ClientUser[]
  codes: SubscriptionCode[]
  deposit_amount: string
  deposit_payment_deadline: DepositPaymentDeadline
  deposit_payment_deadline_display: string
  expire_after: string
  history: BaseHistory[]
  invoice_city: string
  invoice_company: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  invoice_type: InvoiceType
  is_paid: boolean
  notes: ContentNote[]
  notifications: EmailNotification[]
  options: SubscriptionOption[]
  package_wholesale: BasePackageWholesale | null
  package_wholesale_order: string
  payments: Payment[]
  payments_move_history: SubscriptionPaymentMoveHistory[]
  phone: string
  postcode: string
  promotion_marketing_id: number
  required_payment_date: string | null
  seller_id: number
  source_marketing: string
  source_marketing_id: number
  street: string
  tourist_vouchers: SubscriptionTouristVoucher[]
  urls: SubscriptionDetailsUrls
  subscription_product_set: number | null
}

export interface SubscriptionStatusFilter {
  value: SubscriptionStatus
  label: string
}

export const subscriptionStatusList: SubscriptionStatusFilter[] = [
  {
    value: 'unfinished',
    label: 'Niedokończone',
  },
  {
    value: 'cancelled',
    label: 'Anulowane',
  },
  {
    value: 'confirmed',
    label: 'Potwierdzone',
  },
  {
    value: 'completed',
    label: 'Zakończone',
  },
  {
    value: 'initial',
    label: 'Wstępne',
  },
  {
    value: 'initial_cancelled',
    label: 'Wstępne anulowane',
  },
]

import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { GuestWalletScope } from '@models/booking'

export const gastroGenericScopes: { scope: GuestWalletScope; scope_display: string }[] = [
  { scope: 'shop', scope_display: 'Sklep' },
  { scope: 'bistro', scope_display: 'Bistro' },
  { scope: 'cafe', scope_display: 'Kawiarnia' },
  { scope: 'sweet_corner', scope_display: 'Słodki Kącik' },
  { scope: 'feeding', scope_display: 'Restauracja' },
  { scope: 'beach_bar', scope_display: 'Beach Bar' },
  { scope: 'itinerant_trade', scope_display: 'Wózek' },
  { scope: 'vending_machines', scope_display: 'Automaty (Vendingowe)' },
  { scope: 'vending_machines_gastronomy', scope_display: 'Automaty (gastronomiczne)' },
  { scope: 'vending_machines_entertainment', scope_display: 'Automaty (rozrywkowe)' },
  { scope: 'improvements', scope_display: 'Ulepszenia' },
]

export const GastroGenericScopes = (): React.ReactNode => (
  <Col md={12} className="px-0 my-2">
    <FormPlain name="scopes" label="Dostęp do zakupów">
      <Row className="mx-0">
        {gastroGenericScopes.map((row, index) => (
          <FormCheckbox
            key={row.scope}
            name={`scopes[${index}]`}
            value={row.scope}
            label={row.scope_display}
            className="col-4"
          />
        ))}
      </Row>
    </FormPlain>
  </Col>
)

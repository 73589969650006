import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { Button } from 'reactstrap'
import { ProductPackageKind } from '@modules/product-package/models'

interface Props {
  productPackageKind: ProductPackageKind
}

export const EndlessHolidayTransactionActions = ({ productPackageKind }: Props): React.ReactNode => {
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const [showAgreementCreationModal] = useModal('EndlessHolidayCreateModal', {
    onCreate: () => showConfirmationPackageCreation(handleShowAddAgreementModal),
    productPackageKind,
  })

  const handleShowAddAgreementModal = () => {
    showAgreementCreationModal(null)
  }

  const productSubscriptionsKind = {
    voucher: 'PRODUCT_PACKAGE_VOUCHER',
    endless_holiday: 'PRODUCT_PACKAGE',
  }[productPackageKind]

  return (
    <div style={{ bottom: 20, right: 0, position: 'absolute' }} className="d-flex ">
      <Link
        to={NavigationPath.ProductPackageSubscriptionsList.replace(':kind', productSubscriptionsKind)}
        className="mr-2"
      >
        <Button color="light">Pakiety pobytowe</Button>
      </Link>
      <ButtonWithIcon
        icon="uil-plus lh-0"
        text="Dodaj"
        handleClick={showAgreementCreationModal}
        color="green-dark"
        btnClass="btn-tall"
      />
    </div>
  )
}

import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import classnames from 'classnames'
import { Alert } from 'reactstrap'
import { useCountries } from '@components/hooks/use-countries'
import { getById } from '@helpers/utils'
import { ReservationGuestIdCard } from '@modules/reservations/details/base-data/guest/reservation-guest-id-card'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationGuestExpandedContent = ({ booking }: Props): React.ReactNode => {
  const { countries } = useCountries()
  const bookingCountry = getById(countries, booking.country)

  return (
    <div className="pl-3 border-top">
      <GuestInfo icon="uil-map-marker" title="Adres zamieszkania:">
        <Localization street={booking.street} postcode={booking.postcode} city={booking.city} />
        <strong>{bookingCountry?.name || 'brak państwa'}</strong>
      </GuestInfo>

      <GuestInfo icon="uil-postcard" title="Seria i numer dokumentu:">
        <ReservationGuestIdCard booking={booking} />
      </GuestInfo>

      <GuestInfo icon="uil-receipt" title="Dane do faktury:" borderless>
        {booking.invoice_nip ? (
          <>
            <strong>NIP: {booking.invoice_nip}</strong>

            <div className="font-12 mt-1">
              <span>{booking.invoice_company}</span>
              <Localization
                street={booking.invoice_street}
                postcode={booking.invoice_postcode}
                city={booking.invoice_city}
              />
            </div>
          </>
        ) : (
          <span className="text-semi-strong">Brak</span>
        )}
      </GuestInfo>
      {!booking.invoice_company && (
        <Alert className="p-2 alert-info">
          <IconWithText
            alignClassName="align-items-start"
            icon="uil-exclamation-circle font-16"
            text={
              <span className="font-11">
                Podanie danych do faktury jest możliwe tylko podczas zakładania rezerwacji.
              </span>
            }
          />
        </Alert>
      )}
    </div>
  )
}

const GuestInfo = ({ title, icon, children, borderless = false }) => (
  <div className={classnames('py-2', { 'border-bottom': !borderless })}>
    <IconWithText
      icon={classnames('font-16 lh-0', icon)}
      text={title}
      flexClassName="d-block"
      wrapperClassNames="mb-1"
    />
    <div className="ml-3">{children}</div>
  </div>
)

const Localization = ({ street, postcode, city }) => (
  <div>
    <strong>{street || 'brak ulicy'}</strong>
    <strong className="d-block">
      {postcode || 'brak kodu pocztowego'}, {city || 'brak miasta'}
    </strong>
  </div>
)

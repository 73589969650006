import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { formatPriceShort, getById } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { useModal } from '@components/modals/use-modal'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { SubscriptionDetailsActions } from '@modules/subscription/details/information/actions'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { SubscriptionDetailsInformationBaseInPrice } from '@modules/subscription/details/information/in-price'
import { useAppSelector } from '@store/index'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformationBase: React.FC<Props> = ({ subscriptionDetails }) => {
  const user = useAuthenticatedUser()
  const { subscription_product_sets } = useSubscriptionAppData()
  const { crud_permissions } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  const [badgeClass, badgeIcon] = React.useMemo(
    () => getStatusClassIcon(subscriptionDetails.status),
    [subscriptionDetails.status],
  )

  const [handleEdit] = useModal('SubscriptionEditModal', { subscriptionDetails })

  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)

  const selectedSubscriptionSet = getById(subscription_product_sets, subscriptionDetails?.subscription_product_set)

  const isDefaultType = subscriptionDetails.type === 'DEFAULT'

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title="Szczegóły pakietu"
          adminUrl={subscriptionDetails.urls.admin}
          isEditAllowed={
            subscriptionDetails.status !== 'cancelled' &&
            !subscriptionDetails.read_only &&
            user.hasPerm(crud_permissions.update)
          }
          onEdit={handleEdit}
        />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status">
              <BadgeWithIcon icon={badgeIcon} title={subscriptionDetails.status_display} className={badgeClass} />
            </SubscriptionDetailsInformationRow>
            {isDefaultType && !!subscriptionDetails.product ? (
              <SubscriptionDetailsInformationRow name="Produkt">
                {subscriptionDetails.product.name}
              </SubscriptionDetailsInformationRow>
            ) : (
              <SubscriptionDetailsInformationRow name="Ilość kodów">
                {subscriptionDetails.local_mountains +
                  subscriptionDetails.local_sea +
                  subscriptionDetails.local_universal}
              </SubscriptionDetailsInformationRow>
            )}
            {subscriptionDetails.product && subscriptionDetails.type !== 'BOOKING_IMPROVEMENT' && (
              <SubscriptionDetailsInformationBaseInPrice subscriptionProduct={subscriptionDetails.product} />
            )}
            {isDefaultType && (
              <SubscriptionDetailsInformationRow name="Zestaw">
                {selectedSubscriptionSet?.name || 'brak'}
              </SubscriptionDetailsInformationRow>
            )}
            <SubscriptionDetailsInformationRow name="Typ zakwaterowania">
              {accommodationTypesMap[subscriptionDetails.accommodation_type_id]?.name}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Cena brutto">
              {formatPriceShort(subscriptionDetails.total_price_brutto)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Pozostało do zapłaty">
              {formatPriceShort(subscriptionDetails.required_payment)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Kwota kaucji">
              {formatPriceShort(subscriptionDetails.deposit_amount)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Termin płatności kaucji">
              {subscriptionDetails.deposit_payment_deadline_display}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Rodzaj pakietu">
              {subscriptionDetails.kind_display}
              {subscriptionDetails.kind_version && ` (${subscriptionDetails.kind_version_display})`}
            </SubscriptionDetailsInformationRow>
            {subscriptionDetails.type !== 'BOOKING_IMPROVEMENT' && (
              <>
                <SubscriptionDetailsInformationRow name="Rodzaj klienta">
                  {subscriptionDetails.client_kind_display}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Źródło">
                  {subscriptionDetails.source_marketing}
                </SubscriptionDetailsInformationRow>
              </>
            )}
            {subscriptionDetails.package_wholesale && (
              <SubscriptionDetailsInformationRow name="Pakiet hurtowy zbiorczy">
                {subscriptionDetails.package_wholesale.number}
                <CopyToClipboard className="ml-1 font-14" value={subscriptionDetails.package_wholesale.number} />
              </SubscriptionDetailsInformationRow>
            )}
            {subscriptionDetails.package_wholesale_order && (
              <SubscriptionDetailsInformationRow name="Numer zamówienia">
                {subscriptionDetails.package_wholesale_order}
              </SubscriptionDetailsInformationRow>
            )}
            <SubscriptionDetailsInformationRow name="Sprzedawca">
              {subscriptionDetails.seller || 'brak'}
            </SubscriptionDetailsInformationRow>
            <PackageDetailsRow label="Data ważności">
              {toDefaultDateFormat(subscriptionDetails.expire_after)}
            </PackageDetailsRow>
            <PackageDetailsRow label="Data wymaganej płatności">
              {subscriptionDetails.required_payment_date
                ? toDefaultDateFormat(subscriptionDetails.required_payment_date)
                : 'Brak'}
            </PackageDetailsRow>
            {subscriptionDetails.auto_cancel_after_date && (
              <PackageDetailsRow label="Auto anulowanie po">
                {toDefaultDateFormat(subscriptionDetails.auto_cancel_after_date)}
              </PackageDetailsRow>
            )}
            <SubscriptionDetailsInformationRow name="Utworzony przez">
              <>
                {subscriptionDetails.created_by} ({toDefaultDateTimeFormat(subscriptionDetails.created)})
              </>
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
        <SubscriptionDetailsActions subscriptionDetails={subscriptionDetails} />
      </CardBody>
    </Card>
  )
}

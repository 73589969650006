import * as React from 'react'
import { SuccessModal } from '@hyper/modals/success-modal'
import { ErrorModal } from '@hyper/modals/error-modal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Variant } from '@hyper/notifications-models'
import { WarningModal } from '@hyper/modals/warning-modal'

export function NotificationHandler() {
  const { messages, removeMessage } = useNotificationHook()

  return (
    <>
      <ToastContainer icon={false} theme="colored" />
      {messages.map(message => (
        <React.Fragment key={message.id}>
          {message.variant === Variant.Error && (
            <ErrorModal
              isOpen={true}
              title={message.title}
              message={message.message}
              handleClose={() => removeMessage(message)}
              action={message.action}
              buttonText={message.buttonText}
              onBeforeClose={message.onBeforeClose}
            />
          )}

          {message.variant === Variant.Success && (
            <SuccessModal
              isOpen={true}
              messageId={message.id}
              title={message.title}
              message={message.message}
              handleClose={() => removeMessage(message)}
              action={message.action}
              buttonText={message.buttonText}
              onBeforeClose={message.onBeforeClose}
              modalConfig={message.modalConfig}
            />
          )}

          {message.variant === Variant.Warning && (
            <WarningModal
              isOpen={true}
              messageId={message.id}
              title={message.title}
              message={message.message}
              handleClose={() => removeMessage(message)}
              action={message.action}
              buttonText={message.buttonText}
              onBeforeClose={message.onBeforeClose}
              modalConfig={message.modalConfig}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

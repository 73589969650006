import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingImprovementSubscriptionFormInputs } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal'
import { Alert, Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { getVoucherTotalPrice } from '@modules/reception/checkin/step-improvements/improvement-subscription/helpers'
import { ImprovementAvailability } from '@models/booking'

interface Props {
  subscriptionImprovementAvailability: ImprovementAvailability
}

export const ReceptionBookingImprovementSubscriptionModalSummary = ({
  subscriptionImprovementAvailability,
}: Props): React.ReactNode => {
  const { control } = useFormContext<ReceptionBookingImprovementSubscriptionFormInputs>()

  const [vouchers, seller] = useWatch({ control, name: ['vouchers', 'seller'] })
  const filledVouchers = vouchers.filter(voucher => !!voucher.client)

  const totalVoucherAmount = filledVouchers.reduce((acc, voucher) => acc + voucher.amount, 0)

  const getVoucherPrice = (amount: number) => getVoucherTotalPrice(amount, subscriptionImprovementAvailability)

  const totalVoucherPrice = getVoucherPrice(totalVoucherAmount)
  const getGuestVouchersPrice = (amount: number) => getVoucherPrice(amount)

  return (
    <div>
      <h4 className="text-secondary fw-semi-bold mb-0">Podsumowanie:</h4>

      <p className="fw-semi-bold mt-1 text-muted">
        Lista osób, które otrzymają wiadomość e-mail z potwierdzeniem zakupu voucherów:
      </p>

      {filledVouchers.map(voucher => (
        <Row key={voucher.client?.name} className={classNames('border-top py-2 align-items-center')}>
          <Col md={6}>
            <strong className="d-block">{voucher.client?.name}</strong>
            <small>{voucher.client?.email}</small>
          </Col>
          <Col md={3}> {voucher.amount}x voucher</Col>
          <Col md={2} className="font-weight-bold text-danger ml-auto">
            {formatPrice(getGuestVouchersPrice(voucher.amount))}
          </Col>
        </Row>
      ))}

      <Row className="font-weight-bold py-2 border-top">
        <Col md={6} className="text-right">
          Razem
        </Col>
        <Col md={3}> {totalVoucherAmount}x voucher</Col>
        <Col md={2} className="text-danger ml-auto">
          {formatPrice(totalVoucherPrice)}
        </Col>
      </Row>
      <p className="border-top mx-n2 px-2 pt-2">
        Sprzedawca: <span className="font-weight-bold">{seller.label}</span>
      </p>
      <Alert className="p-2 alert-info mt-2">
        <IconWithText
          icon="uil-info-circle align-self-start font-16"
          textClass="font-11"
          text="W kolejnym kroku właściciel rezerwacji otrzymuje e-mail z linkiem do podsumowania zamówienia, warunków sprzedaży i regulaminu. Do końca pobytu musi zapoznać się z dokumentami i zaakceptować regulamin, aby móc kupić voucher przy wymeldowaniu. Osoby towarzyszące także dostaną wiadomość e-mail z linkiem do kupna vouchera, na którego zakup mają 7 dni."
        />
      </Alert>
    </div>
  )
}

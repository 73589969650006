import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import Spinner from '@hyper/spinner'
import { handleEnterPress } from '@helpers/utils'
import classNames from 'classnames'
import classnames from 'classnames'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onCancel: (action?: any) => void
  onAccept: (reason?: string, formParams?: any) => Promise<void>
  isOpen: boolean
  title: string
  askForReason?: boolean
  message?: React.ReactNode
  showLoading?: boolean
  headerClassName?: string
  buttonAcceptClassName?: string
  defaultReason?: string
  submitLabel?: React.ReactNode
}

interface FormInputs {
  reason: string
}

export const ConfirmModal: React.FC<Props> = ({
  title,
  headerClassName,
  buttonAcceptClassName,
  message,
  toggleIsVisible,
  onAccept,
  askForReason,
  showLoading,
  defaultReason,
  submitLabel = 'Potwierdzam',
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      reason: defaultReason || '',
    },
  })

  const isValid = askForReason ? methods.formState.isValid : true

  const handleAccept = async () => {
    if (isValid) {
      const formValues = methods.getValues()
      setIsLoading(true)
      await onAccept(formValues.reason, formValues)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    methods.setFocus('reason')
  }, [methods.setFocus])

  return (
    <>
      <ModalHeader className={classNames(headerClassName)}>{title}</ModalHeader>
      <ModalBody>
        {showLoading ? (
          <div className="text-center">
            <Spinner className="text-primary" />
          </div>
        ) : (
          <FormProvider {...methods}>
            {message && <div>{message}</div>}
            {askForReason && (
              <Row className={classnames({ 'mt-2': message })}>
                <FormInput
                  label="Proszę podać powód operacji"
                  role="confirm-modal-reason"
                  name="reason"
                  onKeyDown={handleEnterPress.bind(handleAccept)}
                  registerParams={{
                    required: true,
                  }}
                />
              </Row>
            )}
          </FormProvider>
        )}
      </ModalBody>
      <ModalFooter className="d-flex">
        <Button color="light" disabled={isLoading} type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          isSaving={isLoading}
          disabled={!isValid}
          type="button"
          role="confirm-modal-submit"
          className={buttonAcceptClassName ? buttonAcceptClassName : 'btn btn-green bootbox-accept'}
          label={submitLabel}
          onClick={handleAccept}
        />
      </ModalFooter>
    </>
  )
}

import { BaseSyntheticEvent } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types'
import { formatDate } from '@helpers/date-helper'

export const extractInnerRef = (params: any) => {
  const { ref, ...rest } = params

  return { innerRef: ref, ...rest }
}

export const handleFormSubmit =
  <T extends FieldValues>(methods: UseFormReturn<T>, onSubmit) =>
  (event: BaseSyntheticEvent) => {
    methods.clearErrors()
    methods.handleSubmit(onSubmit)(event)
  }

export const setCurrencyFieldModifiers = (name, setValue, registerParams) => ({
  onChange: event => {
    setValue?.(name, formValueTransform.asCurrency(event))
  },
  setValueAs: value => {
    if (!value) return ''
    const priceValue = parseFloat(value)
    return registerParams?.setValueAs?.(priceValue) || priceValue
  },
})

export const setPatternFieldModifier = (setValue, pattern: RegExp) => ({
  onChange: event => {
    setValue(event.target.name, formValueTransform.inputPattern(event, pattern))
  },
})

export const formValueTransform = {
  limitLength: (value, maximum) => value.slice(0, maximum),
  asCurrency: event => {
    const availableDecimalSeparators = ['.', ',']
    const currencyInputAvailableCharactersPattern = /[0-9]|[.,]/

    let isSeparatorAlreadyAdded = false

    const stringValue = String(event.target.value)
      .split('')
      .filter(character => {
        if (availableDecimalSeparators.includes(character)) {
          if (isSeparatorAlreadyAdded) return false
          isSeparatorAlreadyAdded = true
        }

        return !!character.match(currencyInputAvailableCharactersPattern)
      })
      .join('')

    return stringValue.replace(',', '.') ?? 0
  },
  inputPattern: (event, pattern: RegExp) => {
    const stringValue = String(event.target.value)
      .split('')
      .filter(character => !!character.match(pattern))
      .join('')

    return stringValue.replace(',', '.') ?? 0
  },
}

export const createFormData = data => {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    formData.append(
      key,
      value instanceof File ? value : value instanceof Date ? formatDate(value, 'yyyy-MM-dd HH:mm') : String(value),
    )
  })

  return formData
}

export const extractUndefinedPropertiesFromObject = <T extends {}>(object: T) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      return { ...acc, [key]: value }
    }

    return acc
  }, {})

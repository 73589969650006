import * as React from 'react'
import classNames from 'classnames'

interface Props {
  children: (isSticky: boolean) => React.ReactNode
  topOffset?: number
  className?: string
}

export const StickyNavbar = ({ children, className, topOffset = 0 }: Props): React.ReactNode => {
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const navbarHeight = React.useMemo(() => {
    let height = 70
    const navbar = document.getElementById('navbar')
    if (navbar) height = navbar.getBoundingClientRect().height
    return height
  }, [isSticky])

  const handleScroll = () => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()
    setIsSticky(rect.top <= navbarHeight + topOffset)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      ref={ref}
      className={classNames(className, 'position-sticky py-1', {
        'bg-white mx-n2 px-2 border-top border-bottom': isSticky,
      })}
      style={{ top: navbarHeight + topOffset, zIndex: 3, transition: 'background 100ms linear' }}
    >
      {children(isSticky)}
    </div>
  )
}
